import React from 'react'
import FinancialTableHeader from '../financials-table/financial-table-header'
import FinancialsTableRowsView from './financial-table-rows'

const FinancialsTableView = ({ data, rowArray }) => {
  return (
    <table className='financialstatementstable'>
      <FinancialTableHeader />
      {/* form fields */}
      <tbody>
        <FinancialsTableRowsView data={data} rowArray={rowArray} />
      </tbody>
      {/* <tbody>
        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft companytitlecard'
          >
            Revenue
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.revenue[0]
              ? ''
              : data.incomeStatement.revenue[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.revenue[1]
              ? ''
              : data.incomeStatement.revenue[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.revenue[2]
              ? ''
              : data.incomeStatement.revenue[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft'
            style={{ fontWeight: '100' }}
          >
            Cost of revenue
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.costOfRevenue[0]
              ? ''
              : data.incomeStatement.costOfRevenue[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.costOfRevenue[1]
              ? ''
              : data.incomeStatement.costOfRevenue[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.costOfRevenue[2]
              ? ''
              : data.incomeStatement.costOfRevenue[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft companytitlecard'
          >
            Gross profit
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.grossProfit[0]
              ? ''
              : data.incomeStatement.grossProfit[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.grossProfit[1]
              ? ''
              : data.incomeStatement.grossProfit[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.grossProfit[2]
              ? ''
              : data.incomeStatement.grossProfit[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerowbreak'></tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft companytitlecard'
          >
            Operating expenses:
          </th>
          <td className='financialstatementstabledatacenter'></td>
          <td className='financialstatementstabledatacenter'></td>
          <td className='financialstatementstabledatalast'></td>
        </tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataindent'
            style={{ fontWeight: '100' }}
          >
            Research and development
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingExpenses.researchAndDevelopment[0]
              ? ''
              : data.incomeStatement.operatingExpenses
                  .researchAndDevelopment[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingExpenses.researchAndDevelopment[1]
              ? ''
              : data.incomeStatement.operatingExpenses
                  .researchAndDevelopment[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.operatingExpenses.researchAndDevelopment[2]
              ? ''
              : data.incomeStatement.operatingExpenses
                  .researchAndDevelopment[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataindent'
            style={{ fontWeight: '100' }}
          >
            Sales and marketing
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingExpenses.salesAndMarketing[0]
              ? ''
              : data.incomeStatement.operatingExpenses.salesAndMarketing[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingExpenses.salesAndMarketing[1]
              ? ''
              : data.incomeStatement.operatingExpenses.salesAndMarketing[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.operatingExpenses.salesAndMarketing[2]
              ? ''
              : data.incomeStatement.operatingExpenses.salesAndMarketing[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataindent'
            style={{ fontWeight: '100' }}
          >
            General and administrative
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingExpenses.generalAndAdministrative[0]
              ? ''
              : data.incomeStatement.operatingExpenses
                  .generalAndAdministrative[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingExpenses.generalAndAdministrative[1]
              ? ''
              : data.incomeStatement.operatingExpenses
                  .generalAndAdministrative[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.operatingExpenses.generalAndAdministrative[2]
              ? ''
              : data.incomeStatement.operatingExpenses
                  .generalAndAdministrative[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft companytitlecard'
          >
            Total operating expenses
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingExpenses.totalOperatingExpenses[0]
              ? ''
              : data.incomeStatement.operatingExpenses
                  .totalOperatingExpenses[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingExpenses.totalOperatingExpenses[1]
              ? ''
              : data.incomeStatement.operatingExpenses
                  .totalOperatingExpenses[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.operatingExpenses.totalOperatingExpenses[2]
              ? ''
              : data.incomeStatement.operatingExpenses
                  .totalOperatingExpenses[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerowbreak'></tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft companytitlecard'
          >
            Operating income / (loss)
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingIncome[0]
              ? ''
              : data.incomeStatement.operatingIncome[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.operatingIncome[1]
              ? ''
              : data.incomeStatement.operatingIncome[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.operatingIncome[2]
              ? ''
              : data.incomeStatement.operatingIncome[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerowbreak'></tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft'
            style={{ fontWeight: '100' }}
          >
            Interest expense
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.interestExpense[0]
              ? ''
              : data.incomeStatement.interestExpense[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.interestExpense[1]
              ? ''
              : data.incomeStatement.interestExpense[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.interestExpense[2]
              ? ''
              : data.incomeStatement.interestExpense[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft'
            style={{ fontWeight: '100' }}
          >
            Other income / (loss), net
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.otherIncomeNet[0]
              ? ''
              : data.incomeStatement.otherIncomeNet[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.otherIncomeNet[1]
              ? ''
              : data.incomeStatement.otherIncomeNet[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.otherIncomeNet[2]
              ? ''
              : data.incomeStatement.otherIncomeNet[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft companytitlecard'
          >
            Income / (loss) before income taxes
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.incomeBeforeIncomeTaxes[0]
              ? ''
              : data.incomeStatement.incomeBeforeIncomeTaxes[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.incomeBeforeIncomeTaxes[1]
              ? ''
              : data.incomeStatement.incomeBeforeIncomeTaxes[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.incomeBeforeIncomeTaxes[2]
              ? ''
              : data.incomeStatement.incomeBeforeIncomeTaxes[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerowbreak'></tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft'
            style={{ fontWeight: '100' }}
          >
            Provision / (benefit) for income taxes
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.provisionForIncomeTaxes[0]
              ? ''
              : data.incomeStatement.provisionForIncomeTaxes[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.provisionForIncomeTaxes[1]
              ? ''
              : data.incomeStatement.provisionForIncomeTaxes[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.provisionForIncomeTaxes[2]
              ? ''
              : data.incomeStatement.provisionForIncomeTaxes[2]}
          </td>
        </tr>

        <tr className='financialstatementstablerow'>
          <th
            colSpan={2}
            className='financialstatementstabledataleft companytitlecard'
          >
            Net income / (net loss)
          </th>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.netIncomeNetLoss[0]
              ? ''
              : data.incomeStatement.netIncomeNetLoss[0]}
          </td>
          <td className='financialstatementstabledatacenter'>
            {!data.incomeStatement.netIncomeNetLoss[1]
              ? ''
              : data.incomeStatement.netIncomeNetLoss[1]}
          </td>
          <td className='financialstatementstabledatalast'>
            {!data.incomeStatement.netIncomeNetLoss[2]
              ? ''
              : data.incomeStatement.netIncomeNetLoss[2]}
          </td>
        </tr>
      </tbody> */}
    </table>
  )
}

export default FinancialsTableView