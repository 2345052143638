import React from 'react';
import { Accordion, Card, Form, Button } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdClose, IoMdInformationCircleOutline, IoMdAdd } from 'react-icons/io';
import '../idea-validation.css';

const CreateEditBusinessViability = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    return (

        <Card>
            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="viable">
                <div className="companyarrowicon">
                    <FaChevronDown className="arrow-icon" />
                </div>

                <div className="companytitlecard">
                    How viable is your business?
                </div>

                <div className="companyellipsisicon">
                    <FaEllipsisH />
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="viable">
                <Card.Body className="ideacheckeraccordioncardbody">
                    <div className="col-12 div-filter">
                        <Form>
                            <Form.Group>
                                <div className="ideacheckerscrollablediv">
                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                Who are your customers?
                                                <sup data-tip="Explain who are the groups of people or businesses you aim to solve the problems and create values for">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.customerSegment.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="customerSegment"
                                                                    value={props.state.customerSegment[i]}
                                                                    placeholder="Who are your customer segments whom you are serving and providing value for?"
                                                                    onChange={(e) => editField({ type: "update customerSegment", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.customerSegment.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove customerSegment", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.customerSegment.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add customerSegment", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What real-world problem are you solving (customer pain)?
                                                <sup data-tip="Very clearly describe the problem (critical pain point) you are solving">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.customerPain.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="customerPain"
                                                                    value={props.state.customerPain[i]}
                                                                    placeholder="What problem are your customers facing that existing solutions in the market are not good enough?"
                                                                    onChange={(e) => editField({ type: "update customerPain", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.customerPain.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove customerPain", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.customerPain.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add customerPain", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                Why now (marketing timing)?
                                                <sup data-tip="Explain how your timing is driven by a shift in consumer behaviour, or a new technological advancement, or a regulatory change, or the disappearance of a previous competitor, or by anything at all">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.marketingTiming.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="marketingTiming"
                                                                    value={props.state.marketingTiming[i]}
                                                                    placeholder="Why is now the right timing for your business offering?"
                                                                    onChange={(e) => editField({ type: "update marketingTiming", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.marketingTiming.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove marketingTiming", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.marketingTiming.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add marketingTiming", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What product-or-solution are you offering (customer gain)?
                                                <sup data-tip="Explain how your product-or-solution solves a fundamental need in the market (whether or not that need is currently known to customers) that customers will pay money to purchase it">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.customerGain.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="customerGain"
                                                                    value={props.state.customerGain[i]}
                                                                    placeholder="How does your product-or-solution solve your customers' problem?"
                                                                    onChange={(e) => editField({ type: "update customerGain", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.customerGain.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove customerGain", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.customerGain.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add customerGain", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                Who are your competitors?
                                                <sup data-tip="Demonstrate that you understand your competitive landscape">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.competitors.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="competitors"
                                                                    value={props.state.competitors[i]}
                                                                    placeholder="Who are your direct competitors and similar companies in your competitive landscape?"
                                                                    onChange={(e) => editField({ type: "update competitors", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.competitors.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove competitors", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.competitors.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add competitors", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What is your competitive differentiation?
                                                <sup data-tip="Explain how you are ten times better or cheaper than existing alternatives">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.competitiveDifferentiation.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="competitiveDifferentiation"
                                                                    value={props.state.competitiveDifferentiation[i]}
                                                                    placeholder="What is your unique value proposition or unique selling point differentiating you from your competition?"
                                                                    onChange={(e) => editField({ type: "update competitiveDifferentiation", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.competitiveDifferentiation.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove competitiveDifferentiation", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.competitiveDifferentiation.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add competitiveDifferentiation", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What is your monetization model?
                                                <sup data-tip="Explain how your business generates revenue and make money">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.monetizationModel.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="monetizationModel"
                                                                    value={props.state.monetizationModel[i]}
                                                                    placeholder="How do you monetize or charge for your product-or-solution offering?"
                                                                    onChange={(e) => editField({ type: "update monetizationModel", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.monetizationModel.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove monetizationModel", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.monetizationModel.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add monetizationModel", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What is your go-to-market strategy?
                                                <sup data-tip="Explain how you plan to acquire customers and if the average selling price of your product-or-solution support your go-to-market strategy">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.marketStrategy.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="marketStrategy"
                                                                    value={props.state.marketStrategy[i]}
                                                                    placeholder="How do you plan to gain visibility and distribution for your product-or-solution to acquire new customers?"
                                                                    onChange={(e) => editField({ type: "update marketStrategy", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.marketStrategy.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove marketStrategy", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.marketStrategy.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add marketStrategy", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                How big is your market (TAM, SAM, SOM)?
                                                <sup data-tip="Explain how your Total Available Market (TAM), Serviceable Available Market (SAM), and Serviceable Obtainable Market (SOM) are large enough to drive business viability and high return on investment">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.marketSegments.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="marketSegments"
                                                                    value={props.state.marketSegments[i]}
                                                                    placeholder="How big are the market segments with the same problem worth solving for and how large can the market become?"
                                                                    onChange={(e) => editField({ type: "update marketSegments", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.marketSegments.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove marketSegments", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.marketSegments.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add marketSegments", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }

                                            <br />

                                            <table id="view-page" className="idea-checker-table">
                                                <tbody>
                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">Total addressable market (TAM)</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="tamAmount" value={props.state.tamAmount} onChange={(e) => { props.setState({ "type": "update tamAmount", "value": parseFloat(e.target.value) }) }} placeholder="Enter amount (in USD)" />
                                                        </td>
                                                        <td className="idea-checker-table-data-right">
                                                            <Form.Control required as="select" name="tamUnit" value={props.state.tamUnit} onChange={(e) => { props.setState({ "type": "update tamUnit", "value": e.target.value }) }}>
                                                                <option>Unit</option>
                                                                <option>K</option>
                                                                <option>M</option>
                                                                <option>B</option>
                                                            </Form.Control>
                                                        </td>
                                                    </tr>
                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">Serviceable available market (SAM)</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="samAmount" value={props.state.samAmount} onChange={(e) => { props.setState({ "type": "update samAmount", "value": parseFloat(e.target.value) }) }} placeholder="Enter amount (in USD)" />
                                                        </td>
                                                        <td className="idea-checker-table-data-right">
                                                            <Form.Control required as="select" name="samUnit" value={props.state.samUnit} onChange={(e) => { props.setState({ "type": "update samUnit", "value": e.target.value }) }}>
                                                                <option>Unit</option>
                                                                <option>K</option>
                                                                <option>M</option>
                                                                <option>B</option>
                                                            </Form.Control>
                                                        </td>
                                                    </tr>
                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">Serviceable obtainable market (SOM)</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="somAmount" value={props.state.somAmount} onChange={(e) => { props.setState({ "type": "update somAmount", "value": parseFloat(e.target.value) }) }} placeholder="Enter amount (in USD)" />
                                                        </td>
                                                        <td className="idea-checker-table-data-right">
                                                            <Form.Control required as="select" name="somUnit" value={props.state.somUnit} onChange={(e) => { props.setState({ "type": "update somUnit", "value": e.target.value }) }}>
                                                                <option>Unit</option>
                                                                <option>K</option>
                                                                <option>M</option>
                                                                <option>B</option>
                                                            </Form.Control>
                                                        </td>
                                                    </tr>
                                                    {/* <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">What percentage of TAM is SAM?</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="percentOfTam" value={props.state.percentOfTam} onChange={(e) => { props.setState({ "type": "update percentOfTam", "value": parseFloat(e.target.value) }) }} placeholder="Enter amount (in percentage)" />
                                                        </td>
                                                        <td className="idea-checker-table-data-right">
                                                            %
                                                        </td>
                                                    </tr>
                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">What percentage of SAM is SOM?</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="percentOfSam" value={props.state.percentOfSam} onChange={(e) => { props.setState({ "type": "update percentOfSam", "value": parseFloat(e.target.value) }) }} placeholder="Enter amount (in percentage)" />
                                                        </td>
                                                        <td className="idea-checker-table-data-right">
                                                            %
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What is your early revenue in Annual Recurring Revenue (ARR)?
                                                <sup data-tip="Prove that your customer segments are willing to pay for your product-or-solution offering">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.earlyRevenue.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="earlyRevenue"
                                                                    value={props.state.earlyRevenue[i]}
                                                                    placeholder="How much money have you made from your target market?"
                                                                    onChange={(e) => editField({ type: "update earlyRevenue", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.earlyRevenue.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove earlyRevenue", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.earlyRevenue.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add earlyRevenue", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }

                                            <br />

                                            <table id="view-page" className="idea-checker-table">
                                                <tbody>
                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">Annual recurring revenue</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="earlyRevenueAmount" value={props.state.earlyRevenueAmount} onChange={(e) => { props.setState({ "type": "update earlyRevenueAmount", "value": parseFloat(e.target.value) }) }} placeholder="Enter amount (in USD)" />
                                                        </td>
                                                        <td className="idea-checker-table-data-right">
                                                            <Form.Control required as="select" name="earlyRevenueUnit" value={props.state.earlyRevenueUnit} onChange={(e) => { props.setState({ "type": "update earlyRevenueUnit", "value": e.target.value }) }}>
                                                                <option>Unit</option>
                                                                <option>K</option>
                                                                <option>M</option>
                                                                <option>B</option>
                                                            </Form.Control>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What is your traction (KPI metrics), if any?
                                                <sup data-tip="Show traction and key metrics to demonstrate demand, progress, and growth">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.kpiMetrics.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="kpiMetrics"
                                                                    value={props.state.kpiMetrics[i]}
                                                                    placeholder="What progress have you achieved so far?"
                                                                    onChange={(e) => editField({ type: "update kpiMetrics", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.kpiMetrics.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove kpiMetrics", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.kpiMetrics.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add kpiMetrics", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }

                                            <br />

                                            <table id="view-page" className="idea-checker-table">
                                                <tbody>
                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">No. of users</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="noOfUsers" value={props.state.noOfUsers} onChange={(e) => { props.setState({ "type": "update noOfUsers", "value": parseInt(e.target.value) }) }} placeholder="Enter no. of users" />
                                                        </td>
                                                    </tr>

                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">No. of active users</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="noOfActiveUsers" value={props.state.noOfActiveUsers} onChange={(e) => { props.setState({ "type": "update noOfActiveUsers", "value": parseInt(e.target.value) }) }} placeholder="Enter no. of users" />
                                                        </td>
                                                    </tr>

                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">Total no. of visitors per month</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="totalVisitorsPerMth" value={props.state.totalVisitorsPerMth} onChange={(e) => { props.setState({ "type": "update totalVisitorsPerMth", "value": parseInt(e.target.value) }) }} placeholder="Enter no. of visitors" />
                                                        </td>
                                                    </tr>

                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">No. of new visitors per month</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="noOfNewVisitorsPerMth" value={props.state.noOfNewVisitorsPerMth} onChange={(e) => { props.setState({ "type": "update noOfNewVisitorsPerMth", "value": parseInt(e.target.value) }) }} placeholder="Enter no. of visitors" />
                                                        </td>
                                                    </tr>

                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">No. of returning visitors per month</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="noOfReturningVisitorsPerMth" value={props.state.noOfReturningVisitorsPerMth} onChange={(e) => { props.setState({ "type": "update noOfReturningVisitorsPerMth", "value": parseInt(e.target.value) }) }} placeholder="Enter no. of visitors" />
                                                        </td>
                                                    </tr>

                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">No. of customers at starting period</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="noOfCustomersAtStartPeriod" value={props.state.noOfCustomersAtStartPeriod} onChange={(e) => { props.setState({ "type": "update noOfCustomersAtStartPeriod", "value": parseInt(e.target.value) }) }} placeholder="Enter no. of customers" />
                                                        </td>
                                                    </tr>

                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">No. of lost customers</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="noOfLostCustomers" value={props.state.noOfLostCustomers} onChange={(e) => { props.setState({ "type": "update noOfLostCustomers", "value": parseInt(e.target.value) }) }} placeholder="Enter no. of customers" />
                                                        </td>
                                                    </tr>

                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">Total no. of customers</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="totalCustomers" value={props.state.totalCustomers} onChange={(e) => { props.setState({ "type": "update totalCustomers", "value": parseInt(e.target.value) }) }} placeholder="Enter no. of customers" />
                                                        </td>
                                                    </tr>

                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">No. of existing customers in the next month</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="noOfExistingCustomersInNxtMth" value={props.state.noOfExistingCustomersInNxtMth} onChange={(e) => { props.setState({ "type": "update noOfExistingCustomersInNxtMth", "value": parseInt(e.target.value) }) }} placeholder="Enter no. of customers" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What is your financing strategy?
                                                <sup data-tip="Outline your financing requirements, timeline, and how you will achieve your key milestones with the funding">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.financingStrategy.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="financingStrategy"
                                                                    value={props.state.financingStrategy[i]}
                                                                    placeholder="Why are you seeking investment or financing?"
                                                                    onChange={(e) => editField({ type: "update financingStrategy", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.financingStrategy.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove financingStrategy", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.financingStrategy.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add financingStrategy", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }

                                            <br />

                                            <table id="view-page" className="idea-checker-table">
                                                <tbody>
                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">Earnings Before Interest, Taxes, Depreciation, and Amortization (EBITDA)</th>
                                                        <td className="idea-checker-table-data-right">
                                                            <Form.Control className="inputbox" type="number" min="0" name="EBITDA" value={props.state.EBITDA} onChange={(e) => { props.setState({ "type": "update EBITDA", "value": parseFloat(e.target.value) }) }} placeholder="Enter value" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What is your business valuation?
                                                <sup data-tip="Explain how you calculated and derived the pre-money and post-money valuation of your business">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.businessValuation.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="businessValuation"
                                                                    value={props.state.businessValuation[i]}
                                                                    placeholder="What is the economic value of your business?"
                                                                    onChange={(e) => editField({ type: "update businessValuation", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.businessValuation.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove businessValuation", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.businessValuation.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add businessValuation", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }

                                            <br />

                                            <table id="view-page" className="idea-checker-table">
                                                <tbody>
                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">Valuation amount</th>
                                                        <td className="idea-checker-table-data-center">
                                                            <Form.Control className="inputbox" type="number" min="1" name="valuationAmount" value={props.state.valuationAmount} onChange={(e) => { props.setState({ "type": "update valuationAmount", "value": parseFloat(e.target.value) }) }} placeholder="Enter amount (in USD)" />
                                                        </td>
                                                        <td className="idea-checker-table-data-right">
                                                            <Form.Control required as="select" name="valuationUnit" value={props.state.valuationUnit} onChange={(e) => { props.setState({ "type": "update valuationUnit", "value": e.target.value }) }}>
                                                                <option>Unit</option>
                                                                <option>K</option>
                                                                <option>M</option>
                                                                <option>B</option>
                                                            </Form.Control>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                What is your business exit strategy, if any? Please provide the solvency-debt ratio value.
                                                <sup data-tip="Demonstrate that you have an exit strategy to make an equitable profit from a transfer of business ownership to a third party via a merger, or acquisition, or initial public offering (IPO), or any liquidity event">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.exitStrategy.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="exitStrategy"
                                                                    value={props.state.exitStrategy[i]}
                                                                    placeholder="What exit strategy have you planned and structured for your business to optimize return on investment?"
                                                                    onChange={(e) => editField({ type: "update exitStrategy", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.exitStrategy.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove exitStrategy", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.exitStrategy.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add exitStrategy", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }

                                            <br />

                                            <table id="view-page" className="idea-checker-table">
                                                <tbody>
                                                    <tr className="idea-checker-table-row">
                                                        <th className="idea-checker-table-data-left companytitlecard">Solvency-debt ratio</th>
                                                        <td className="idea-checker-table-data-right">
                                                            <Form.Control className="inputbox" type="number" min="0" name="solvencyDebtRatio" value={props.state.solvencyDebtRatio} onChange={(e) => { props.setState({ "type": "update solvencyDebtRatio", "value": parseFloat(e.target.value) }) }} placeholder="Enter value" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="ideacheckerrow">
                                        <div>
                                            <Form.Label>
                                                Why you (team experience)?
                                                <sup data-tip="Explain why you and/or your team are uniquely qualified to win the market">
                                                    <IoMdInformationCircleOutline id="information-icon" />
                                                </sup>
                                            </Form.Label>
                                            {
                                                props.state.teamExperience.map((x, i) => {
                                                    return (
                                                        <div key={i} style={{ display: "flex", marginTop: "10px" }}>
                                                            <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                <Form.Control
                                                                    name="teamExperience"
                                                                    value={props.state.teamExperience[i]}
                                                                    placeholder="What unique skill set, background, or experience that you and/or your co-founder(s) have to build this business?"
                                                                    onChange={(e) => editField({ type: "update teamExperience", value: e.target.value, index: i })}
                                                                    style={{ borderColor: "transparent" }}
                                                                />
                                                                {
                                                                    props.state.teamExperience.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove teamExperience", index: i }); }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }
                                                            </div>
                                                            {
                                                                props.state.teamExperience.length - 1 === i &&
                                                                <Button className="pitchdeckaddfield"
                                                                    onClick={() => { addField({ type: "add teamExperience", value: "" }) }}>
                                                                    <IoMdAdd />
                                                                </Button>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>

    )

}

export default CreateEditBusinessViability;