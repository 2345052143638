import React from "react";
import { Form } from "react-bootstrap";
import AccordianCard from "../../../../../../accordion-card/accordian-card";
import { categories } from "../../../../../../dropdown-options/categories";
import { competitiveMoats } from "../../../../../../dropdown-options/competitive-moats";
import { countries } from "../../../../../../dropdown-options/countries";
import { entityTypes } from "../../../../../../dropdown-options/entity-types";
import { industriesNew } from "../../../../../../dropdown-options/industries";
import { stagesOfDevelopment } from "../../../../../../dropdown-options/stages-of-development";
import { transactionalModelTypes } from "../../../../../../dropdown-options/transactional-model-types";
import "../../../pitch-deck-analysis.css";
import FormCell from "../../form/form-cell";
import FormCellDate from "../../form/form-cell-date";
import FormCellMultiselect from "../../form/form-cell-multiselect";
import ResultsSection from "../results-section";

const CreateEditOverview = (props) => {
  return (
    <div className="ideacheckertabbody">
      <div className="ideacheckerquestions">
        <AccordianCard
          className="companyaccordion"
          title="General information"
          activeKey="generalinformation"
          infoText={
            <>
              Please fill in all required fields marked with asterisks (
              <sup>*</sup>).
            </>
          }
        >
          <Form>
            <Form.Group>
              <div className="ideacheckergrid">
                <FormCell
                  props={props}
                  actualName="entityName"
                  displayName="Entity name"
                  placeholder="What is the name of your entity?"
                  bottomText="Enter entity name"
                />

                <FormCellMultiselect
                  props={props}
                  required={true}
                  actualName="entityType"
                  displayName="Entity type"
                  datatip="Select the business entity type that dictates both the legal structure of your organization and how your company will be taxed"
                  placeholder="Which entity best describes your operation?"
                  options={entityTypes}
                  bottomText="Select entity type"
                  selectionLimit={1}
                />

                <FormCellMultiselect
                  props={props}
                  required={true}
                  actualName="ownershipStatus"
                  displayName="Ownership status"
                  datatip=""
                  placeholder="What is the ownership status of your entity?"
                  options={[
                    { key: "Acquired" },
                    { key: "Private" },
                    { key: "Bankrupt" },
                    { key: "Subsidiary" },
                    { key: "Government" },
                    { key: "Public" },
                    { key: "Public Benefit" },
                    { key: "Merged" },
                  ]}
                  bottomText="Select ownership status"
                  selectionLimit={1}
                />

                <FormCellMultiselect
                  props={props}
                  required={true}
                  actualName="markets"
                  displayName="Market(s)"
                  datatip="Select your market location(s) where you offer and sell your product-or-solution"
                  placeholder="Which market(s) are you targeting?"
                  options={countries}
                  bottomText="Select market(s) (maximum 5)"
                  selectionLimit={5}
                />
              </div>

              <div className="ideacheckergrid">
                <FormCell
                  props={props}
                  actualName="locationCity"
                  displayName="Headquarter location - city"
                  placeholder="Which city is your headquarter located at?"
                  bottomText="Enter city"
                />

                <FormCell
                  props={props}
                  actualName="locationState"
                  displayName="Headquarter location - state"
                  placeholder="Which state is your headquarter located at?"
                  bottomText="Enter state"
                />

                <FormCellMultiselect
                  props={props}
                  required={true}
                  actualName="locationCountry"
                  displayName="Headquarter location - country"
                  datatip="Select the country where your company is at"
                  placeholder="Which country is your headquarter located at?"
                  options={countries}
                  bottomText="Select country"
                  selectionLimit={1}
                />
              </div>

              <FormCell
                props={props}
                required={true}
                actualName="onesentencePitch"
                displayName="One-sentence pitch"
                placeholder="Briefly describe what your entity does in less than 50 words"
                bottomText="Describe what you do in 10 to 50 words"
              />

              <div className="ideacheckergrid">
                <FormCellMultiselect
                  props={props}
                  required={true}
                  actualName="stageOfDevelopment"
                  displayName="Stage of development"
                  datatip="Describe your current stage of business development"
                  placeholder="What is your current stage of development?"
                  options={stagesOfDevelopment}
                  bottomText="Select development stage"
                  selectionLimit={1}
                />
                <FormCellMultiselect
                  props={props}
                  required={true}
                  actualName="transactionalModelType"
                  displayName="Transactional model type"
                  datatip="Select the transactional model type that best describe the relationship of transactions between your organization and your counterparts"
                  placeholder="Which model best describes your commercial activity?"
                  options={transactionalModelTypes}
                  bottomText="Select transactional model type"
                  selectionLimit={1}
                />
                <FormCellMultiselect
                  props={props}
                  required={true}
                  actualName="defensibilityMoats"
                  displayName="Competitive moat(s)"
                  datatip="Select the defensibility moat that best define your competitive advantage to protect long-term profits and market share"
                  placeholder="Which moat best defines your defensibility?"
                  options={competitiveMoats}
                  bottomText="Select competitive moat(s) (maximum 5)"
                  selectionLimit={5}
                />
                <FormCellMultiselect
                  props={props}
                  required={true}
                  actualName="categories"
                  displayName="Categories"
                  datatip="Select the categories that best define the specific niche of your business focus in a vertical market."
                  placeholder="What categories best define your idea?"
                  options={categories}
                  bottomText="Select categories (maximum 5)"
                  selectionLimit={5}
                />
                <FormCellMultiselect
                  props={props}
                  required={true}
                  actualName="industry"
                  displayName="Industry"
                  datatip="Select the industrial grouping that best describe the production process, functions, and operations of your core business"
                  placeholder="Which industry best describes your area of focus?"
                  options={industriesNew}
                  bottomText="Select industry"
                  selectionLimit={1}
                />
              </div>
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title="Goals"
          activeKey="goals"
          style={{ marginTop: "10px" }}
        >
          <Form>
            <Form.Group>
              <FormCell
                props={props}
                actualName="vision"
                displayName="Vision"
                placeholder="Over the span of 10 years, what do you wish to build?"
              />
              <FormCell
                props={props}
                actualName="mission"
                displayName="Mission"
                placeholder="In 3 years, what do you intend to achieve?"
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title="Funding"
          activeKey="funding"
          style={{ marginTop: "10px" }}
        >
          <Form>
            <Form.Group>
              <div className="ideacheckergrid">
                <FormCellDate
                  props={props}
                  actualName="lastFinancingDate"
                  displayName="Last financing date"
                  bottomText="Enter last financing date"
                />
                <FormCell
                  props={props}
                  actualName="lastFinancingRound"
                  displayName="Last financing round"
                  placeholder="What was your last financing round?"
                  bottomText="Enter last financing round"
                />
                <FormCell
                  props={props}
                  actualName="numOfFundingRounds"
                  displayName="No. of funding rounds"
                  placeholder="How many rounds of funding have you raised?"
                  bottomText="Enter no. of funding rounds"
                />
              </div>
            </Form.Group>
          </Form>
        </AccordianCard>
      </div>

      <ResultsSection data={props.state.analysisResult} type="overall" />
    </div>
  );
};

export default CreateEditOverview;
