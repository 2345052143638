import { Form } from "react-bootstrap";
import AccordianCard from "../../../../../../accordion-card/accordian-card";
import {
  editItemInFieldArray,
  pushItemToFieldArray,
  removeItemFromFieldArray,
} from "../../../helpers";
import "../../../pitch-deck-analysis.css";
import FormCell from "../../form/form-cell";
import FormCellMultiselect from "../../form/form-cell-multiselect";
import ResultsSection from "../results-section";
import FormCellMultiInput from "../../form/form-cell-multi-input";
const CreateEditBusiness = (props) => {
  props = {
    ...props,
    pushItemToFieldArray,
    removeItemFromFieldArray,
    editItemInFieldArray,
  };

  return (
    <div className="ideacheckertabbody">
      <div className="ideacheckerquestions">
        <AccordianCard
          className="companyaccordion"
          title="Business description"
          activeKey="businessDescription"
        >
          <Form>
            <Form.Group>
              <FormCell
                props={props}
                actualName="businessDescription"
                displayName="Business description"
                placeholder="Briefly describe what your entity does in less than 300 words"
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title="Problem"
          activeKey="problem"
          style={{ marginTop: "10px" }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName="customerPain"
                displayName="What real-world problem are you solving (customer pain)?"
                datatip="Clearly describe the problems that your customers are facing whereby existing solutions in the market are not good enough."
              />
              <FormCellMultiInput
                props={props}
                actualName="areYouSolvingPersonalCriticalPainPoint"
                displayName="Are you solving a personal critical pain point?"
                datatip="If yes, explain how you encountered this critical problem?"
              />
              <FormCellMultiInput
                props={props}
                actualName="howProblemIsAddressed"
                displayName="How is the problem which you described being addressed in today's market?"
                datatip="Explain how the problems are being solved by existing solutions providers in today's market."
              />
              <FormCellMultiInput
                props={props}
                actualName="currentSolutionsShortcomings"
                displayName="What are the shortcomings to the current solutions in the market?"
                datatip="Explain how the current solutions in the market are not good enough to fully resolve the problems you described."
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title="Growth model"
          activeKey="growthModel"
          style={{ marginTop: "10px" }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName="howToGrowBusiness"
                displayName="How do you intend to grow the business?"
                datatip="Explain how you are growing your business to the next stage."
              />
              <FormCellMultiInput
                props={props}
                actualName="unitEconomics"
                displayName="What does the unit economics look like at scale, particularly when competitors enter the market and as your defensibility moat develops?"
                datatip="Show your unit economics to quantitatively demonstrate your business growth potential at scale and ability to defend your business."
              />
              <FormCellMultiInput
                props={props}
                actualName="doesPriceChange"
                displayName="Is your business the type that looks to increase or decrease prices as you scale?"
                datatip="a. Define the type of business.<br />b. Explain how your products-or-services' prices increase or decrease as your business scales."
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title="Business valuation"
          activeKey="businessValuation"
          style={{ marginTop: "10px" }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName="preMoneyValuation"
                displayName="What is your pre-money valuation at the current stage?"
                datatip="Determine the economic value of your business before it received outside investment. Need help? Estimate your business value with our pre-revenue valuation simulator."
              />
              <FormCellMultiInput
                props={props}
                actualName="postMoneyValuation"
                displayName="What is your post-money valuation at the current stage?"
                datatip="Determine the economic value of your business after it received a round of financing, or estimate your projected valuation based on your fundraising target amount if your business have not received financing. Need help? Estimate your business value with our pre-revenue valuation simulator."
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title="Equity stake"
          activeKey="equityStake"
          style={{ marginTop: "10px" }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName="equityOwnershipStakeToInvestors"
                displayName="How much equity ownership stake of your company are you offering to your investor(s)?"
                datatip="Determine the percentage of equity ownership stake of your company that you decide to offer your investor(s)."
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title="Financing strategy"
          activeKey="financingStrategy"
          style={{ marginTop: "10px" }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiselect
                props={props}
                required={true}
                actualName="financingRound"
                displayName="State the financing round that you are fundraising for."
                placeholder="Which entity best describes your operation?"
                options={[
                  { key: "Accelerator" },
                  { key: "Angel" },
                  { key: "Convertible Note" },
                  { key: "Equity Crowdfunding" },
                  { key: "Grant" },
                  { key: "Incubator" },
                  { key: "Mezzanine" },
                  { key: "PIPE Financing" },
                  { key: "Post-IPO Debt" },
                  { key: "Post-IPO Equity" },
                  { key: "Post-IPO Secondary" },
                  { key: "Post-Seed" },
                  { key: "Pre-IPO" },
                  { key: "Pre-Seed" },
                  { key: "Pre-series A" },
                  { key: "Pre-series B" },
                  { key: "Pre-series C" },
                  { key: "SAFE" },
                  { key: "Secondary Market" },
                  { key: "Seed" },
                  { key: "Series A" },
                  { key: "Series B" },
                  { key: "Series C" },
                  { key: "Series D" },
                  { key: "Series E" },
                  { key: "Series F" },
                  { key: "Series G" },
                  { key: "Series H" },
                  { key: "Series I" },
                  { key: "Series J" },
                ]}
                bottomText="Select financing round"
                selectionLimit={1}
              />
              <FormCellMultiInput
                props={props}
                actualName="financingStrategy"
                displayName="What is your financing strategy?"
                datatip=""
              />
              <FormCellMultiInput
                props={props}
                actualName="howToUseMoney"
                displayName="How do you intend to use the money (use of proceeds)?"
                datatip=""
              />
              <FormCellMultiInput
                props={props}
                actualName="howMuchMoneyToRaise"
                displayName="How much money do you need to raise?"
                datatip=""
              />
              <FormCellMultiInput
                props={props}
                actualName="howMuchExpectedFromInvestors"
                displayName="How much do you expect from investors?"
                datatip=""
              />
              <FormCellMultiInput
                props={props}
                actualName="milestonesToAchieveBeforeRaiseMoney"
                displayName="What are the key milestones that you have to achieve before raising money again?"
                datatip=""
              />
              <FormCellMultiInput
                props={props}
                actualName="areYouRaisingEnoughToAccomplishMilestones"
                displayName="Are you raising enough money to accomplish the
                              milestones you set, such that the next investor
                              will be willing to invest new money at a
                              substantially higher valuation than the current
                              round?"
                datatip=""
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title="Exit strategy"
          activeKey="exitStrategy"
          style={{ marginTop: "10px" }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName="exitStrategy"
                displayName="What is your business exit strategy?"
                datatip=""
              />
              <FormCellMultiInput
                props={props}
                actualName="howInvestorsGetMoney"
                displayName="How will the investors ultimately get their money
                              out of the business, assuming that it is
                              successful, even if only marginally so?"
                datatip=""
              />
            </Form.Group>
          </Form>
        </AccordianCard>
      </div>

      <ResultsSection data={props.state.analysisResult} type="business" />
    </div>
  );
};

export default CreateEditBusiness;
