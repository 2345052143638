import React from "react";
import { Form } from "react-bootstrap";
import AccordianCard from "../../../../../../accordion-card/accordian-card";
import {
  editItemInFieldArray,
  pushItemToFieldArray,
  removeItemFromFieldArray,
} from "../../../helpers";
import "../../../pitch-deck-analysis.css";
import FormCellMultiInput from "../../form/form-cell-multi-input";
import ResultsSection from "../results-section";

const CreateEditMarket = (props) => {
  props = {
    ...props,
    pushItemToFieldArray,
    removeItemFromFieldArray,
    editItemInFieldArray,
  };

  return (
    <div className="ideacheckertabbody">
      <div className="ideacheckerquestions">
        <AccordianCard
          className="companyaccordion"
          title="Customers"
          activeKey="customers"
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName="customerSegments"
                displayName="Who are your customer segments whom you are
                              serving and providing value for?"
                datatip="Determine who your customers are."
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          style={{ marginTop: "10px" }}
          title="Market sizing"
          activeKey="marketSizing"
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName="marketSegments"
                displayName="How big are the market segments with the same
                              problem worth solving for and how large can the
                              market become?"
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          style={{ marginTop: "10px" }}
          title="Opportunity"
          activeKey="opportunity"
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName="problemProductSolves"
                displayName="What is the specific customer problem that your
                              product-or-service solves?"
              />
              <FormCellMultiInput
                props={props}
                actualName="doesProductMakeLivesBetter"
                displayName="Does the product-or-service you're proposing make
                              your customers' lives better?"
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          style={{ marginTop: "10px" }}
          title="Market timing"
          activeKey="marketTiming"
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName="marketTiming"
                displayName="Why now (market timing)?"
                datatip="Explain why your business offering is the right timing for your target market."
              />
            </Form.Group>
          </Form>
        </AccordianCard>
      </div>

      <ResultsSection data={props.state.analysisResult} type="market" />
    </div>
  );
};

export default CreateEditMarket;
