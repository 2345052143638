import React from "react";
import { Form } from "react-bootstrap";
import { capitalize } from "../../../../../../utils/helpers";

const FinancialTableRow = ({
  props,
  fieldName,
  displayName = fieldName,
  className,
}) => {
  const { updateFieldItem } = props;

  const year0 = fieldName + "Year0";
  const year1 = fieldName + "Year1";
  const year2 = fieldName + "Year2";

  return (
    <tr className="financialstatementstablerow">
      <th
        colSpan={2}
        className={className}
      >
        {capitalize(displayName)}
      </th>
      <td className="financialstatementstabledatacenter">
        <Form.Control
          type="number"
          min="0"
          name={year2}
          value={props.state[year2]}
          onChange={(e) => updateFieldItem(props, year2, e.target.value)}
        />
      </td>
      <td className="financialstatementstabledatacenter">
        <Form.Control
          type="number"
          min="0"
          name={year1}
          value={props.state[year1]}
          onChange={(e) => updateFieldItem(props, year1, e.target.value)}
        />
      </td>
      <td className="financialstatementstabledatalast">
        <Form.Control
          type="number"
          min="0"
          name={year0}
          value={props.state[year0]}
          onChange={(e) => updateFieldItem(props, year0, e.target.value)}
        />
      </td>
    </tr>
  );
};

export default FinancialTableRow;