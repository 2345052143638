import React from 'react'
import { capitalize } from '../../../../../../utils/helpers'

const FinancialTableRow = ({
  data,
  fieldName,
  displayName = fieldName,
  className
}) => {
  const year0 = fieldName + 'Year0'
  const year1 = fieldName + 'Year1'
  const year2 = fieldName + 'Year2'

  return (
    <tr className='financialstatementstablerowview'>
      <th
        colSpan={2}
        className='financialstatementstabledataleft'
        style={{ fontWeight: '100' }}
      >
        {capitalize(displayName)}
      </th>
      <td className='financialstatementstabledatacenter'>
        {!data[year0] ? '-' : data[year0]}
      </td>
      <td className='financialstatementstabledatacenter'>
        {!data[year1] ? '-' : data[year1]}
      </td>
      <td className='financialstatementstabledatalast'>
        {!data[year2] ? '-' : data[year2]}
      </td>
    </tr>
  )
}

export default FinancialTableRow