import React from "react";
import AccordianCard from "../../../../../../accordion-card/accordian-card";
import "../../../pitch-deck-analysis.css";
import PitchDeckDetails from "../../view-details/details-component";
import PitchDeckContentLayout from "../../../../../../../layout/pitch-deck-analysis-page/pitch-deck-content-layout";

function ViewOperations({ data }) {
  return (
    <PitchDeckContentLayout data={data} type="operations">
      <AccordianCard
        className="companyaccordion"
        title="Traction"
        activeKey="traction"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What is your traction (KPI metrics)?`,
              fieldName: "kpiMetrics",
              type: "bullets",
            },
            {
              displayName: `What are the traction metrics that show what you have
                    achieved so far?`,
              fieldName: "metricsThatShowAchievement",
              type: "bullets",
            },
            {
              displayName: `What are the week-over-week or month-over-month growth rate?`,
              fieldName: "growthRate",
              type: "bullets",
            },
            {
              displayName: `Is there proof of latent demand?`,
              fieldName: "proofOfLatentDemand",
              type: "bullets",
            },
            {
              displayName: `Is your business growing?`,
              fieldName: "isBusinessGrowing",
              type: "bullets",
            },
            {
              displayName: `Is your product-or-service self-perpetuating its growth?`,
              fieldName: "isProductSelfPerpetuatingGrowth",
              type: "bullets",
            },
            {
              displayName: `Have you been able to accomplish with less money in less
                    time?`,
              fieldName: "ableToAccomplishWithLess",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>
    </PitchDeckContentLayout>
  );
}

export default ViewOperations;
