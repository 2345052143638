import React from "react";
import AccordianCard from "../../../../../../accordion-card/accordian-card";
import "../../../pitch-deck-analysis.css";
import PitchDeckDetail from "../../view-details/detail";
import PitchDeckDetails from "../../view-details/details-component";
import PitchDeckContentLayout from "../../../../../../../layout/pitch-deck-analysis-page/pitch-deck-content-layout";

const ViewBusiness = ({ data }) => {
  return (
    <PitchDeckContentLayout data={data} type="business">
      <AccordianCard
        className="companyaccordion"
        title="Business description"
        activeKey="businessdescription"
      >
        <PitchDeckDetail
          data={data}
          displayName="Business description"
          fieldName="businessDescription"
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Problem"
        activeKey="problem"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What real-world problem are you solving (customer pain)?`,
              fieldName: "customerPain",
              type: "bullets",
            },
            {
              displayName: `Are you solving a personal critical pain point?`,
              fieldName: "areYouSolvingPersonalCriticalPainPoint",
              type: "bullets",
            },
            {
              displayName: `How is the problem which you described being addressed in today's market?`,
              fieldName: "howProblemIsAddressed",
              type: "bullets",
            },
            {
              displayName: `What are the shortcomings to the current solutions in the market?`,
              fieldName: "currentSolutionsShortcomings",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Growth model"
        activeKey="growthmodel"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: "How do you intend to grow the business?",
              fieldName: "howToGrowBusiness",
              type: "bullets",
            },
            {
              displayName: `What does the unit economics look like at scale,
                    particularly when competitors enter the market and as your
                    defensibility moat develops?`,
              fieldName: "unitEconomics",
              type: "bullets",
            },
            {
              displayName: `Is your business the type that looks to increase or decrease
                    prices as you scale?`,
              fieldName: "doesPriceChange",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Valuation"
        activeKey="valuation"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: "What is your pre-money valuation?",
              fieldName: "preMoneyValuation",
              type: "bullets",
            },
            {
              displayName: `What is your post-money valuation?`,
              fieldName: "postMoneyValuation",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Equity stake"
        activeKey="equitystake"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `How much equity ownership stake of your company are you
                    offering to your investor(s)?`,
              fieldName: "equityOwnershipStakeToInvestors",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Financing strategy"
        activeKey="financingstrategy"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: "Financing round",
              fieldName: "financingRound",
            },
            {
              displayName: `What is your financing strategy?`,
              fieldName: "financingStrategy",
              type: "bullets",
            },
            {
              displayName: `How do you intend to use the money (use of proceeds)?`,
              fieldName: "howToUseMoney",
              type: "bullets",
            },
            {
              displayName: `How much money do you need to raise?`,
              fieldName: "howMuchMoneyToRaise",
              type: "bullets",
            },
            {
              displayName: `How much do you expect from investors?`,
              fieldName: "howMuchExpectedFromInvestors",
              type: "bullets",
            },
            {
              displayName: `What are the key milestones that you have to achieve before
                    raising money again?`,
              fieldName: "milestonesToAchieveBeforeRaiseMoney",
              type: "bullets",
            },
            {
              displayName: `Are you raising enough money to accomplish the milestones
                    you set, such that the next investor will be willing to
                    invest new money at a substantially higher valuation than
                    the current round?`,
              fieldName: "areYouRaisingEnoughToAccomplishMilestones",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Exit strategy"
        activeKey="exitStrategy"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What is your business exit strategy?`,
              fieldName: "exitStrategy",
              type: "bullets",
            },
            {
              displayName: `How will the investors ultimately get their money out of the
                    business, assuming that it is successful, even if only
                    marginally so?`,
              fieldName: "howInvestorsGetMoney",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>
    </PitchDeckContentLayout>
  );
};

export default ViewBusiness;
