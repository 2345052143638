import React, { useState } from 'react'
import './reset-password-form.css'
import { Card, Button, Form, Alert } from 'react-bootstrap'
import HeaderLogo from '../../../assets/navbar/roiquant-600x200px-white-01.png';


function ResetPasswordForm(props) {

  const [passwordShown, setPasswordShown] = useState(false);

  //Password toggle handler (New password)
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [cpasswordShown, setcPasswordShown] = useState(false);

  //Password toggle handler (Confirm new password)
  const togglecPassword = () => {
    setcPasswordShown(!cpasswordShown);
  };

  const setNewPassword = (e) => {
    props.setCredentials(Object.assign({}, props.credentials, { newPwd: e.target.value }))
  }

  const setConfirmPassword = (e) => {
    props.setCredentials(Object.assign({}, props.credentials, { confirmPwd: e.target.value },))
  }

  return (

    <div id="changepasswordcontent">
      <div className="changepasswordtop">
        <a href="https://www.roiquant.com/" target="_blank" rel="noopener noreferrer">
          <img src={HeaderLogo} alt="roiquant" width="180px" height="60px" className="roiquantlogo" />
        </a>
      </div>

      <div>{props.response}</div>

      <div id="changepasswordform">
        <Card className="changepasswordcard">
          <div className="changepasswordheader">

            <h3>Reset your password</h3>
          </div>

          <div className="createaccnote">
            <p>We'll email you to confirm your password reset.</p>
          </div>

          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Control className="inputbox" type="email" name="email" placeholder="Enter email" value={props.credentials.email}
                onChange={(e) => { props.setCredentials(Object.assign({}, props.credentials, { email: e.target.value })) }}
              >
              </Form.Control>
            </Form.Group>

            <div id="validation" style={{ width: "100%", display: "flex", flexDirection: "row" }}>

              <Form.Group controlId="formNewPassword"
                style={{ width: "50%", paddingRight: "1.5%" }}
              >
                <Form.Control className="inputbox" type={passwordShown ? "text" : "password"} name="newpassword" placeholder="Enter new password"
                  onChange={e => { setNewPassword(e); }}
                >
                </Form.Control>

                <a href="#" className='showpassword' onClick={togglePassword}>{passwordShown ? "Hide" : "Show"} password</a>

                <div id="checksField">
                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.newPwd) == 0 ? "grey" : props.checkPwdLength(props.credentials.newPwd) ? "green" : "red" }}>At least 8 characters or as long as possible</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.newPwd) == 0 ? "grey" : props.checkPwdSymbol(props.credentials.newPwd) ? "green" : "red" }}>Include at least 1 symbol character </p>

                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.newPwd) == 0 ? "grey" : props.checkPwdUppercase(props.credentials.newPwd) ? "green" : "red" }}>At least 1 uppercase letter</p>
                </div>

              </Form.Group>

              <Form.Group
                style={{ width: "50%" }} >
                <Form.Control className="inputbox" type={cpasswordShown ? "text" : "password"} name="confirmpassword" placeholder="Confirm new password"
                  onChange={e => { setConfirmPassword(e); }}
                >
                </Form.Control>

                <a href="#" className='showcpassword' onClick={togglecPassword}>{cpasswordShown ? "Hide" : "Show"} password</a>

                <div id="checksField">
                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.newPwd) == 0 ? "grey" : props.checkPwdNum(props.credentials.newPwd) ? "green" : "red" }}>Include at least 1 number</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.newPwd) == 0 ? "grey" : props.checkPwdLowercase(props.credentials.newPwd) ? "green" : "red" }}>At least 1 lowercase letter</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.credentials.newPwd) == 0 ? "grey" : props.checkPwdMatch(props.credentials.newPwd, props.credentials.confirmPwd) ? "green" : "red" }}>Both passwords must match </p>
                </div>

              </Form.Group>

            </div>

            <div style={{ textAlign: "center" }}>
              <Button type="submit" className="changepasswordbtn glintanimation" onClick={(event) => { event.preventDefault(); props.handleChangePwd(props.credentials) }}>
                <span>Reset password</span>
              </Button>
            </div>

          </Form>

          <div className="loginnote">
            <span>Got your password? <a href="/login">Log in</a></span>
          </div>

        </Card>
      </div>
    </div>
  )
}

export default ResetPasswordForm;