import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./pitch-deck-analysis.css";
// import CreateEditManagement from './create-edit-pitch-deck-analysis/management';
import PlanBuilderPageLayout from "../../../../layout/pitch-deck-analysis-page/index";
import { useRealmApp } from "../../../../RealmApp";
import useBusinessPlanBuilder from "../../../hooks/useBusinessPlanBuilder";
import {
  closeAlert,
  handleClose,
  handleEnterSave,
  handleSave,
  handleSubmit,
  scrollToTop,
} from "./helpers";

const CreatePitchDeckAnalysis = () => {
  const { state, dispatch } = useBusinessPlanBuilder();

  // hook for alert message
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");

  // get current user
  const { currentUser } = useRealmApp();

  const { analyzePitchDeck, savePitchDeck, generateAddEditFormId } =
    currentUser.functions;

  // use history to navigate page
  const history = useHistory();

  // realm function to generate id
  useEffect(() => {
    if (!state.pitchDeckId) {
      async function getPitchDeckId() {
        const id = await generateAddEditFormId();
        dispatch({ type: "update pitchDeckId", value: id });
      }
      try {
        getPitchDeckId();
      } catch (err) {
        // To-do: Alert user an error was returned
        // console.error("Unable to generate unique ID");
      }
    }
  }, []);

  const props = {
    state: state,
    dispatch: dispatch,
    setMessage,
    setAlert,
    message,
    alert,
    history,
    analyzePitchDeck,
    savePitchDeck,
  };

  const params = {
    ...props,
    closeAlert: () => closeAlert(props),
    scrollToTop: () => scrollToTop(),
    handleSubmit: () => handleSubmit(props),
    handleSave: () => handleSave(props),
    handleEnterSave: (e) => handleEnterSave(e),
    handleClose: () => handleClose(props),
  };

  return <PlanBuilderPageLayout props={params} />;
};

export default CreatePitchDeckAnalysis;
