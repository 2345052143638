import React from 'react';
import '../idea-validation.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';

function ViewBusinessViability(props) {

    return (

        <Card>
            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="howViableIsYourBusiness">
                <div className="companyarrowicon">
                    <FaChevronDown />
                </div>

                <div className="companytitlecard">
                    How viable is your business?
                </div>

                <div className="companyellipsisicon">
                    <FaEllipsisH />
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="howViableIsYourBusiness">
                <Card.Body id="companyinformation">
                    <div>
                        <p className="companydetailtitle">
                            Who are your customers?
                            <sup data-tip="Explain who are the groups of people or businesses you aim to solve the problems and create values for">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.customerSegment
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.customerSegment.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What real-world problem are you solving (customer pain)?
                            <sup data-tip="Very clearly describe the problem (critical pain point) you are solving">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.customerPain
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.customerPain.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            Why now (marketing timing)?
                            <sup data-tip="Explain how your timing is driven by a shift in consumer behaviour, or a new technological advancement, or a regulatory change, or the disappearance of a previous competitor, or by anything at all">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.marketingTiming
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.marketingTiming.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What product-or-solution are you offering (customer gain)?
                            <sup data-tip="Explain how your product-or-solution solves a fundamental need in the market (whether or not that need is currently known to customers) that customers will pay money to purchase it">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.customerGain
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.customerGain.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            Who are your competitors?
                            <sup data-tip="Demonstrate that you understand your competitive landscape">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.competitors
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.competitors.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your competitive differentiation?
                            <sup data-tip="Explain how you are ten times better or cheaper than existing alternatives">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.competitiveDifferentiation
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.competitiveDifferentiation.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your monetization model?
                            <sup data-tip="Explain how your business generates revenue and make money">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.monetizationModel
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.monetizationModel.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your go-to-market strategy?
                            <sup data-tip="Explain how you plan to acquire customers and if the average selling price of your product-or-solution support your go-to-market strategy">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.marketStrategy
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.marketStrategy.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            How big is your market (TAM, SAM, SOM)?
                            <sup data-tip="Explain how your Total Available Market (TAM), Serviceable Available Market (SAM), and Serviceable Obtainable Market (SOM) are large enough to drive business viability and high return on investment">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.marketSegments
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.marketSegments.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>

                        <table className="idea-checker-table">
                            <tbody>
                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">Total addressable market (TAM)</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.tamAmount
                                            ? "-"
                                            : "USD" + props.tamAmount + " " + props.tamUnit
                                        }
                                    </td>
                                </tr>
                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">Serviceable available market (SAM)</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.samAmount
                                            ? "-"
                                            : "USD" + props.samAmount + " " + props.samUnit
                                        }
                                    </td>
                                </tr>
                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">Serviceable obtainable market (SOM)</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.somAmount
                                            ? "-"
                                            : "USD" + props.somAmount + " " + props.somUnit
                                        }
                                    </td>
                                </tr>
                                {/* <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">What percentage of TAM is SAM?</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.percentOfTam
                                            ? "-"
                                            : props.percentOfTam + "%"
                                        }
                                    </td>
                                </tr>
                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">What percentage of SAM is SOM?</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.percentOfSam
                                            ? "-"
                                            : props.percentOfSam + "%"
                                        }
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>

                    <div style={{ paddingTop: "2rem" }}>
                        <p className="companydetailtitle">
                            What is your early revenue in Annual Recurring Revenue (ARR)?
                            <sup data-tip="Prove that your customer segments are willing to pay for your product-or-solution offering">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.earlyRevenue
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.earlyRevenue.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>

                        <table className="idea-checker-table">
                            <tbody>
                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">Annual recurring revenue</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.earlyRevenueAmount
                                            ? "-"
                                            : "USD" + props.earlyRevenueAmount + " " + props.earlyRevenueUnit
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{ paddingTop: "2rem" }}>
                        <p className="companydetailtitle">
                            What is your traction (KPI metrics), if any?
                            <sup data-tip="Show traction and key metrics to demonstrate demand, progress, and growth">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.kpiMetrics
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.kpiMetrics.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>

                        <table className="idea-checker-table">
                            <tbody>
                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">No. of users</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.noOfUsers
                                            ? "-"
                                            : props.noOfUsers
                                        }
                                    </td>
                                </tr>

                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">No. of active users</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.noOfActiveUsers
                                            ? "-"
                                            : props.noOfActiveUsers
                                        }
                                    </td>
                                </tr>

                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">Total no. of visitors per month</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.totalVisitorsPerMth
                                            ? "-"
                                            : props.totalVisitorsPerMth
                                        }
                                    </td>
                                </tr>

                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">No. of new visitors per month</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.noOfNewVisitorsPerMth
                                            ? "-"
                                            : props.noOfNewVisitorsPerMth
                                        }
                                    </td>
                                </tr>

                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">No. of returning visitors per month</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.noOfReturningVisitorsPerMth
                                            ? "-"
                                            : props.noOfReturningVisitorsPerMth
                                        }
                                    </td>
                                </tr>

                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">No. of customers at starting period</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.noOfCustomersAtStartPeriod
                                            ? "-"
                                            : props.noOfCustomersAtStartPeriod
                                        }
                                    </td>
                                </tr>

                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">No. of lost customers</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.noOfLostCustomers
                                            ? "-"
                                            : props.noOfLostCustomers
                                        }
                                    </td>
                                </tr>

                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">Total no. of customers</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.totalCustomers
                                            ? "-"
                                            : props.totalCustomers
                                        }
                                    </td>
                                </tr>

                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">No. of existing customers in the next month</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.noOfExistingCustomersInNxtMth
                                            ? "-"
                                            : props.noOfExistingCustomersInNxtMth
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{ paddingTop: "2rem" }}>
                        <p className="companydetailtitle">
                            What is your financing strategy?
                            <sup data-tip="Outline your financing requirements, timeline, and how you will achieve your key milestones with the funding">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.financingStrategy
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.financingStrategy.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>

                        <table className="idea-checker-table">
                            <tbody>
                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">Earnings Before Interest, Taxes, Depreciation, and Amortization (EBITDA)</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.EBITDA
                                            ? "-"
                                            : props.EBITDA
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{ paddingTop: "2rem" }}>
                        <p className="companydetailtitle">
                            What is your business valuation?
                            <sup data-tip="Explain how you calculated and derived the pre-money and post-money valuation of your business">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.businessValuation
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.businessValuation.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>

                        <table className="idea-checker-table">
                            <tbody>
                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">Valuation amount</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.valuationAmount
                                            ? "-"
                                            : "USD" + props.valuationAmount + " " + props.valuationUnit
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{ paddingTop: "2rem" }}>
                        <p className="companydetailtitle">
                            What is your business exit strategy, if any?
                            <sup data-tip="Demonstrate that you have an exit strategy to make an equitable profit from a transfer of business ownership to a third party via a merger, or acquisition, or initial public offering (IPO), or any liquidity event">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.exitStrategy
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.exitStrategy.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>

                        <table className="idea-checker-table">
                            <tbody>
                                <tr className="idea-checker-table-row">
                                    <th colSpan={2} className="idea-checker-table-data-left companytitlecard">Solvency-debt ratio</th>
                                    <td className="idea-checker-table-data-right">
                                        {!props?.solvencyDebtRatio
                                            ? "-"
                                            : props.solvencyDebtRatio
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{ paddingTop: "2rem" }}>
                        <p className="companydetailtitle">
                            Why you (team experience)?
                            <sup data-tip="Explain why you and/or your team are uniquely qualified to win the market">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata" style={{ marginBottom: "1rem" }}>
                            {!props?.teamExperience
                                ? "-"
                                : (<ul style={{ marginBottom: "0" }}>{props.teamExperience.map((point, index) =>
                                    !point
                                        ? "-"
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>

    )

}

export default ViewBusinessViability;