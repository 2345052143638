import React from "react";
import ResultsSection from "../../components/tools/founders/pitch-deck-analysis/components/sections/results-section";

const PitchDeckContentLayout = ({ children, data, type }) => {
  return (
    <div className="ideacheckertabbody">
      <div className="ideacheckerquestions">{children}</div>

      <ResultsSection data={data.results} type={type} />
    </div>
  );
};

export default PitchDeckContentLayout;
