import Tab from '@mui/material/Tab'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useState } from 'react'
import ViewMarket from '../sections/view-pitch-deck-analysis/market'
import ViewSolution from '../sections/view-pitch-deck-analysis/solution'
import ViewBusiness from '../sections/view-pitch-deck-analysis/business'
import ViewOverview from '../sections/view-pitch-deck-analysis/overview'
import ViewCompetition from '../sections/view-pitch-deck-analysis/competition'
import ViewOperations from '../sections/view-pitch-deck-analysis/operations'
import ViewMarketing from '../sections/view-pitch-deck-analysis/marketing'
import ViewFinancials from '../sections/view-pitch-deck-analysis/financials'
import ViewRisk from '../sections/view-pitch-deck-analysis/risk'
import DataRoomView from '../../../../../data-room/data-room-view'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

const StyledTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    // maxWidth: 40,
    width: '100%',
    backgroundColor: '#1f5f8b'
  }
})

const StyledTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontFamily: ['AileronRegular', 'OpenSansRegular'].join(','),
    // fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    // marginRight: theme.spacing(1),
    color: '#808080',
    '&.Mui-selected': {
      color: '#1f5f8b'
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgb(3, 155, 229)'
    }
  })
)

export default function ViewPlanBuilderTabs({ data }) {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobilevariant='scrollable'
        className='planbuildertabs'
        defaultActiveKey='overview'
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 }
          }
        }}
      >
        <StyledTab label='Overview' {...a11yProps(0)} />
        <StyledTab label='Business' {...a11yProps(1)} />
        <StyledTab label='Solution' {...a11yProps(2)} />
        <StyledTab label='Market' {...a11yProps(3)} />
        <StyledTab label='Competition' {...a11yProps(4)} />
        <StyledTab label='Operations' {...a11yProps(5)} />
        <StyledTab label='Marketing' {...a11yProps(6)} />
        <StyledTab label='Financials' {...a11yProps(7)} />
        <StyledTab label='Risk' {...a11yProps(8)} />
        <StyledTab label='Management' disabled {...a11yProps(9)} />
        <StyledTab label='Data Room' {...a11yProps(10)} />
      </StyledTabs>

      <TabPanel value={value} index={0}>
        <ViewOverview data={data} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <ViewBusiness data={data} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <ViewSolution data={data} />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <ViewMarket data={data} />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <ViewCompetition data={data} />
      </TabPanel>

      <TabPanel value={value} index={5}>
        <ViewOperations data={data} />
      </TabPanel>

      <TabPanel value={value} index={6}>
        <ViewMarketing data={data} />
      </TabPanel>

      <TabPanel value={value} index={7}>
        <ViewFinancials data={data} />
      </TabPanel>

      <TabPanel value={value} index={8}>
        <ViewRisk data={data} />
      </TabPanel>

      <TabPanel value={value} index={9}>
        {/* <CreateEditManagement /> */}
      </TabPanel>

      <TabPanel value={value} index={10}>
        <DataRoomView />
      </TabPanel>
    </>
  )
}