import React, { useReducer, useEffect, useState } from "react";
import "./pitch-deck-analysis.css";
import NavBar from "../../../../layout/navbar/navbar";
import {
  Button,
  Alert,
  Container,
  Form,
  Spinner,
  Modal,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { FaEllipsisH } from "react-icons/fa";
import { IoMdClose, IoMdInformationCircleOutline } from "react-icons/io";
import { BiAnalyse } from "react-icons/bi";
import { AiOutlineCheckSquare, AiOutlineEdit } from "react-icons/ai";
import { GoArchive } from "react-icons/go";
import { useRealmApp } from "../../../../RealmApp";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const PitchDeckAnalysis = () => {
  // hook for alert component
  const [alert, setAlert] = useState(true);

  // hook for alert message
  const [message, setMessage] = useState(
    <div style={{ display: "flex", paddingTop: "10px" }}>
      <div style={{ width: "90%" }}>
        <p style={{ color: "#0f9d58" }}>
          <BiAnalyse className="alert-icon" /> Loading
        </p>
        <p style={{ marginLeft: "28px", fontSize: "14px" }}>
          Won't be long now. We're always doing our best to reduce waiting time.
        </p>
        <p style={{ marginLeft: "28px", fontSize: "14px" }}>
          If your waiting time is too long,{" "}
          <a
            href="https://www.roiquant.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </a>
          .
        </p>
      </div>
      <div style={{ width: "10%", textAlign: "right" }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    </div>
  );

  // function to close alert component
  function closeAlert() {
    setAlert(false);
    setMessage("");
  }

  const browseHistory = useHistory();

  function goToPreviousPath() {
    browseHistory.goBack();
  }

  const { currentUser, getUserTier } = useRealmApp();

  // declare realm functions
  const {
    getUsersDocuments,
    signNda,
    updateFinancingStatusOfPitchDeck,
    archivePitchDeck,
  } = currentUser.functions;

  // hook for documents
  const [state, dispatch] = useReducer(reducer, {
    isDataInitialized: false,
    showNdaModal: false,
    nda: {
      consent: "Select...",
      agree: "Select...",
    },
    pitchDecks: [],
  });

  // function to update documents
  function reducer(state, action) {
    switch (action.type) {
      case "update showNdaModal": {
        return { ...state, showNdaModal: action.value };
      }

      case "update nda": {
        return { ...state, nda: action.value };
      }

      case "update pitchDecks": {
        return { ...state, pitchDecks: action.value };
      }

      case "initialize pitchDecks": {
        return { ...state, pitchDecks: action.value, isDataInitialized: true };
      }

      case "add Nda and initialize pitchDecks": {
        return {
          ...state,
          showNdaModal: true,
          pitchDecks: action.value,
          isDataInitialized: true,
        };
      }

      default: {
        return { ...state };
      }
    }
  }

  // get documents on page load
  useEffect(() => {
    async function getPitchDecks(userSignedNda) {
      const response = await getUsersDocuments({
        feature: "Business plan builder",
      });

      const pitchDecks = JSON.parse(response);

      closeAlert();

      if (!userSignedNda) {
        dispatch({
          type: "add Nda and initialize pitchDecks",
          value: pitchDecks,
        });
      } else {
        dispatch({ type: "initialize pitchDecks", value: pitchDecks });
      }
    }
    if (currentUser.customData.signedNdaPD) {
      if (state.isDataInitialized === false) {
        try {
          getPitchDecks(true);
        } catch (err) {
          // To-do: Alert user an error was returned
          // console.error("getUsersDocuments Error: ", err.error);
        }
      }
    } else {
      if (state.isDataInitialized === false) {
        try {
          getPitchDecks(false);
        } catch (err) {
          // To-do: Alert user an error was returned
          // console.error(err);
        }
      }
    }
  }, [state.pitchDecks]);

  // hook for id
  const [archiveId, setArchiveId] = useState();

  // hook for archive modal
  const [archiveModal, setArchiveModal] = useState(false);

  // function to open archive modal
  const openArchiveModal = (e) => {
    setArchiveId(e);
    setArchiveModal(true);
  };

  // function to close archive modal
  function closeArchiveModal() {
    setArchiveId();
    setArchiveModal(false);
  }

  // function to archive document
  async function archiveConfirmation() {
    // hide modal component
    closeArchiveModal();

    // show alert message
    setAlert(true);
    setMessage(
      <div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
          <p style={{ color: "#0f9d58" }}>
            <BiAnalyse className="alert-icon" /> Archiving
          </p>
          <p style={{ marginLeft: "28px" }}>
            We'll notify you once your business plan is archived.
          </p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
          <Spinner animation="border" variant="secondary" />
        </div>
      </div>
    );

    try {
      const response = await archivePitchDeck(archiveId);

      const data = JSON.parse(response);

      dispatch({ type: "update pitchDecks", value: data });

      setAlert(true);
      setMessage(
        <div style={{ paddingTop: "10px" }}>
          <p style={{ color: "#5aa469" }}>
            <AiOutlineCheckSquare className="alert-icon" /> Archived
          </p>
          <p style={{ marginLeft: "28px" }}>Your business plan is archived.</p>
        </div>
      );
    } catch (err) {
      // To-do: Alert user an error was returned
      // console.error(err);
    }

    // close alert
    closeAlert();
  }

  // function to update financing status
  async function changeFinancingStatus(pitchDeckId, action) {
    let newFinancingStatus;

    switch (action) {
      case "Pitch": {
        newFinancingStatus = "Fundraising";
        break;
      }

      case "Cancel": {
        newFinancingStatus = "Cancelled";
        break;
      }

      case "Pause": {
        newFinancingStatus = "On hold";
        break;
      }

      case "Complete": {
        newFinancingStatus = "Raised";
        break;
      }

      default: {
        newFinancingStatus = null;
      }
    }

    if (newFinancingStatus) {
      try {
        const response = await updateFinancingStatusOfPitchDeck({
          pitchDeckId: pitchDeckId,
          newStatus: newFinancingStatus,
        });
        const updatedList = JSON.parse(response);

        dispatch({ type: "update pitchDecks", value: updatedList });
      } catch (err) {
        // To-do: Alert user an error was returned
        // console.error("Couldn't update the selected pitch deck's financingStatus:", err);
      }
    }
  }

  // function to submit nda
  async function handleSignNda() {
    if (state.nda.consent === "I consent" && state.nda.agree === "I agree") {
      // console.log("Form is valid and should be saved to user profile. This form will not show the next time user opens this page.");
      try {
        await signNda({ type: "Pitch Deck" });
        await currentUser.refreshCustomData();
        dispatch({ type: "update showNdaModal", value: false });
      } catch (error) {
        dispatch({ type: "update showNdaModal", value: true });
      }
    }
  }

  // function to update nda
  function changeNda(field, value) {
    const ndaCopy = {
      consent: state.nda.consent,
      agree: state.nda.agree,
    };

    ndaCopy[field] = value;

    dispatch({ type: "update nda", value: ndaCopy });
  }

  return (
    <>
      {/* navbar */}
      <NavBar />

      {/* alert message */}
      {alert ? (
        <Alert className="error-message floating-alert" variant="light">
          <div>
            <div style={{ textAlign: "right" }}>
              <Button className="pitchdeckmessageclose" onClick={closeAlert}>
                <IoMdClose />
              </Button>
            </div>
            {message}
          </div>
        </Alert>
      ) : (
        ""
      )}

      <div id="pitchdeck">
        <Container className="col-11" style={{ paddingBottom: "200px" }}>
          <div className="pitchdeckheaderdiv">
            {/* title */}
            <div className="pitchdecktitlediv">
              <br />
              <span className="pitchdecktext">
                Business plan builder
                <sup data-tip="Learn to improve your commercial viability and competitive differentiation as you build your business plan with our AI-powered business plan builder. The guided questions are only meant to support your building process and the unanswered questions will be excluded from the analysis.">
                  <IoMdInformationCircleOutline id="information-icon" />
                </sup>
              </span>
            </div>

            {/* buttons */}
            {!getUserTier() || getUserTier() < 2 ? (
              ""
            ) : (
              <div
                className="pitchdeckstatsdiv"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <a href="/founders/business-plan/create">
                  <Button className="pitchdeckaddbutton glintanimation">
                    <span>Create</span>
                  </Button>
                </a>

                <DropdownButton
                  className="ellipsis-dropdown sort-column-button"
                  title={<FaEllipsisH />}
                  style={{ marginLeft: "20px" }}
                >
                  <Dropdown.Item href="/founders/business-plan/archive">
                    Archive
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
          </div>

          {!getUserTier() || getUserTier() < 2 ? (
            <Modal
              className="modal-div"
              show="true"
              onHide={goToPreviousPath}
              fullscreen="sm-down"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton style={{ borderBottom: "none" }} />
              <Modal.Body>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Get started with Standard Plan at USD $7 per month
                </p>

                <p style={{ textAlign: "left" }}>
                  Analyze your commercial viability while building your business
                  plan.
                </p>

                <div style={{ textAlign: "center" }}>
                  <a
                    href="https://www.roiquant.com/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="modal-compare glintanimation">
                      Compare plans
                    </Button>
                  </a>
                </div>

                <hr style={{ margin: "1.5rem 0" }} />

                <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                  Things to know:
                </p>

                <ul className="modal-list">
                  <li>Downgrade your subscription plan at any time</li>
                  <li>Once payment is made, there is no refund</li>
                  <li>
                    If you downgrade or cancel, you will no longer have access
                    to the features that are only available in the subscription
                    plan which you downgraded or cancelled from
                  </li>
                </ul>

                <div style={{ textAlign: "center" }}>
                  <a
                    href="https://www.roiquant.com/tools/founders/business-plan-builder"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="modal-product glintanimation">
                      Learn more about the product
                    </Button>
                  </a>
                </div>
              </Modal.Body>
            </Modal>
          ) : (
            <>
              <Modal
                className="modal-div ideacheckernda"
                show={state.showNdaModal}
                onHide={() => goToPreviousPath()}
                fullscreen="sm-down"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                  <div style={{ paddingBottom: "20px" }}>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      Confidentiality Agreement
                    </p>

                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      Privacy Protection
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Thank you for using our roiquant Products and Solutions.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      We offer our Solutions with integrity and care in which we
                      hope that you will enjoy using.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Roiquant values the privacy of every individual's personal
                      information and is committed to the protection of that
                      information from unauthorized use and disclosure except
                      where permitted by law. For information about handling of
                      your personal information, please read our{" "}
                      <a
                        href="https://viewpoints.roiquant.com/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      ,{" "}
                      <a
                        href="https://viewpoints.roiquant.com/terms-of-service/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </a>
                      ,{" "}
                      <a
                        href="https://viewpoints.roiquant.com/user-notice/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        User Notice
                      </a>
                      , and{" "}
                      <a
                        href="https://viewpoints.roiquant.com/disclaimer/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Disclaimer
                      </a>
                      .
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      For more information about{" "}
                      <a
                        href="https://viewpoints.roiquant.com/is-flipidea-gdpr-compliant/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Data Protection
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://viewpoints.roiquant.com/what-is-the-gdpr/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        General Data Protection Regulation (GDPR)
                      </a>{" "}
                      at roiquant, please see our{" "}
                      <a
                        href="https://viewpoints.roiquant.com/faq/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Frequently Asked Questions
                      </a>
                      .
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      If you have any questions about how roiquant is collecting
                      and handling your personal information, please write us at{" "}
                      <a
                        href="mailto:privacy@roiquant.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy@roiquant.com
                      </a>
                      .
                    </p>
                  </div>

                  <div style={{ paddingBottom: "20px" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      Confidential Information
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      You, as our guest user or paying customer, acknowledge
                      that your access to and use of roiquant Products and
                      Solutions, agree that any text, data, content, code,
                      video, personal data protected by privacy laws, or other
                      materials of any type that you upload, submit, or
                      otherwise transmit to or through the roiquant platform may
                      or may not be confidential, and can be used in accordance
                      with any agreement it may have with you as well as by
                      roiquant according to our{" "}
                      <a
                        href="https://viewpoints.roiquant.com/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy policy
                      </a>
                      .
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Roiquant's business intelligence platform constantly
                      aggregate public data, documents, and information
                      regarding business development, investment transactions,
                      market trend, and so on, to which roiquant processed,
                      analyzed, and published.
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Roiquant collects information about you when you provide
                      them to us, when you use our Products and Solutions, and
                      when other sources provide them to us. For more
                      information, please read{" "}
                      <a
                        href="https://viewpoints.roiquant.com/what-information-do-we-collect-about-you/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontStyle: "italic" }}
                      >
                        what information do we collect
                      </a>
                      .
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      It is important to note that how we use the information we
                      collect depends in part on which Products and Solutions
                      you use, how you use them, and any preferences you have
                      communicated to us. For more information, please read{" "}
                      <a
                        href="https://viewpoints.roiquant.com/how-we-use-the-information-we-collect-about-you/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontStyle: "italic" }}
                      >
                        how we use the information we collect
                      </a>
                      .
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      <sup>*</sup>If you do not provide your consent, roiquant
                      will not be able to process or enable your usage of our
                      products and solutions.
                    </p>
                  </div>

                  <div style={{ margin: "0 15px", paddingBottom: "20px" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      Personal information consent<sup>*</sup>
                    </p>

                    <Form>
                      <Form.Group>
                        <Form.Row className="align-items-center ideacheckerdropdownnda">
                          <Form.Control
                            as="select"
                            name="consent"
                            value={state.nda.consent}
                            onChange={(e) =>
                              changeNda(e.target.name, e.target.value)
                            }
                          >
                            <option>Select...</option>
                            <option>I consent</option>
                            <option>I do not consent</option>
                          </Form.Control>
                        </Form.Row>
                      </Form.Group>
                    </Form>

                    <div className="ideacheckerndanote">
                      <span>
                        I consent to the personal information, may or may not be
                        confidential, that I provide to roiquant for using its
                        products and solutions.<sup>*</sup>
                      </span>
                    </div>
                  </div>

                  <div style={{ paddingBottom: "20px" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      Keeping Information Safe
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Roiquant would protect your information with the same
                      safeguards we would apply to our own confidential
                      information:
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      (a) keeping it in a safe and secure place
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      (b) taking steps to prevent unauthorized access
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      (c) notifying you immediately if there is reason to
                      believe the information has been disclosed to someone it
                      should not have been
                    </p>
                  </div>

                  <div style={{ paddingBottom: "20px" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      Disclosure of Confidential Information
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      Confidential information can only be disclosed by roiquant
                      where:
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      (a) it becomes publicly available through some other route
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      (b) it was already (lawfully) in the possession of the
                      person receiving it
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      (c) ordered to disclose the information by a government or
                      regulatory body and is legally obliged to do so
                    </p>

                    <p style={{ textAlign: "justify" }}>
                      I understand that as a roiquant user or customer, I must
                      accept roiquant's policies, at all times.
                    </p>
                  </div>

                  <div style={{ margin: "0 15px", paddingBottom: "20px" }}>
                    <p
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      I agree to the terms of this Confidentiality Agreement:
                      <sup>*</sup>
                    </p>

                    <Form>
                      <Form.Group>
                        <Form.Row className="align-items-center ideacheckerdropdownnda">
                          <Form.Control
                            as="select"
                            name="agree"
                            value={state.nda.agree}
                            onChange={(e) =>
                              changeNda(e.target.name, e.target.value)
                            }
                          >
                            <option>Select...</option>
                            <option>I agree</option>
                            <option>I do not agree</option>
                          </Form.Control>
                        </Form.Row>
                      </Form.Group>
                    </Form>

                    <div style={{ textAlign: "center" }}>
                      <button
                        onClick={() => goToPreviousPath()}
                        className="ideacheckercancelnda"
                      >
                        Cancel
                      </button>

                      <Button
                        className="ideacheckersubmitnda glintanimation"
                        onClick={() => handleSignNda()}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* table */}
              <div>
                <table className="pitchdeckhistorytable">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Action</th>
                      <th>Financing Status</th>
                      <th>Version</th>
                      <th>Modified</th>
                      <th>Edit</th>
                      <th>Archive</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.pitchDecks?.length === 0 ? (
                      <tr className="pitchdecktablerow">
                        <td className="pitchdecktabledata">-</td>
                        <td className="pitchdecktabledata">-</td>
                        <td className="pitchdecktabledata">-</td>
                        <td className="pitchdecktabledata">-</td>
                        <td className="pitchdecktabledata">-</td>
                        <td className="pitchdecktabledata">-</td>
                        <td className="pitchdecktabledata">-</td>
                      </tr>
                    ) : (
                      state.pitchDecks?.map((pitchDeck, index) => (
                        <tr key={index} className="pitchdecktablerow">
                          <td className="pitchdecktabledata">
                            <Link
                              to={`/founders/business-plan/view/${pitchDeck.pitchDeckId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="shutdowndatalink"
                            >
                              {pitchDeck.name
                                ? pitchDeck.name
                                : "Untitled business plan"}
                            </Link>
                          </td>
                          <td className="pitchdecktabledata">
                            <Form className="pitchdeckselectaction">
                              <Form.Group>
                                <Form.Control
                                  as="select"
                                  onChange={(e) =>
                                    changeFinancingStatus(
                                      pitchDeck.pitchDeckId,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option></option>
                                  <option>Pitch</option>
                                  <option>Cancel</option>
                                  <option>Pause</option>
                                  <option>Complete</option>
                                </Form.Control>
                              </Form.Group>
                            </Form>
                          </td>
                          <td className="pitchdecktabledata">
                            {pitchDeck.financingStatus}
                          </td>
                          <td className="pitchdecktabledata">
                            {pitchDeck.version ? pitchDeck.version : "-"}
                          </td>
                          <td className="pitchdecktabledata">
                            {pitchDeck.lastModified
                              ? pitchDeck.lastModified.substring(0, 10)
                              : "-"}
                          </td>
                          <td className="pitchdecktabledata">
                            <Link
                              to={`/founders/business-plan/edit/${pitchDeck.pitchDeckId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="shutdowndatalink"
                            >
                              <Button className="edit-button">
                                <AiOutlineEdit />
                              </Button>
                            </Link>
                          </td>
                          <td className="pitchdecktabledata">
                            <Button
                              className="edit-button"
                              onClick={() =>
                                openArchiveModal(pitchDeck.pitchDeckId)
                              }
                            >
                              <GoArchive />
                            </Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </Container>
      </div>

      {/* archive modal */}
      <Modal
        className="modal-div"
        show={archiveModal}
        onHide={closeArchiveModal}
        fullscreen="sm-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }} />
        <Modal.Body>
          <p style={{ textAlign: "center" }}>
            Are you sure you want to archive this business plan?
          </p>

          <div style={{ textAlign: "center" }}>
            <button className="cancelbutton" onClick={closeArchiveModal}>
              Cancel
            </button>

            <button
              className="modal-delete glintanimation"
              onClick={archiveConfirmation}
            >
              Archive
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PitchDeckAnalysis;
