import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip'; // Import ReactTooltip
import CreateEditBusiness from '../sections/create-edit-pitch-deck-analysis/business';
import CreateEditCompetition from '../sections/create-edit-pitch-deck-analysis/competition';
import CreateEditFinancials from '../sections/create-edit-pitch-deck-analysis/financials';
import CreateEditMarket from '../sections/create-edit-pitch-deck-analysis/market';
import CreateEditMarketing from '../sections/create-edit-pitch-deck-analysis/marketing';
import CreateEditOperations from '../sections/create-edit-pitch-deck-analysis/operations';
import CreateEditOverview from '../sections/create-edit-pitch-deck-analysis/overview';
import CreateEditRisk from '../sections/create-edit-pitch-deck-analysis/risk';
import CreateEditSolution from '../sections/create-edit-pitch-deck-analysis/solution';
import DataRoomComponent from '../../../../../data-room/data-room-component';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
      <ReactTooltip id={`tooltip-${index}`} effect='solid' /> {/* Add ReactTooltip here */}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const StyledTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#1f5f8b',
  },
});

const StyledTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontFamily: ['AileronRegular', 'OpenSansRegular'].join(','),
    fontSize: theme.typography.pxToRem(18),
    color: '#808080',
    '&.Mui-selected': {
      color: '#1f5f8b',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgb(3, 155, 229)',
    },
  })
);

export default function PlanBuilderTabs({ props, type }) {
  const { state, dispatch } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Rebuild tooltips when the active tab changes
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [value]);

  return (
    <>
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
        className='planbuildertabs'
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <StyledTab label='Overview' {...a11yProps(0)} />
        <StyledTab label='Business' {...a11yProps(1)} />
        <StyledTab label='Solution' {...a11yProps(2)} />
        <StyledTab label='Market' {...a11yProps(3)} />
        <StyledTab label='Competition' {...a11yProps(4)} />
        <StyledTab label='Operations' {...a11yProps(5)} />
        <StyledTab label='Marketing' {...a11yProps(6)} />
        <StyledTab label='Financials' {...a11yProps(7)} />
        <StyledTab label='Risk' {...a11yProps(8)} />
        <StyledTab label='Management' disabled {...a11yProps(9)} />
        <StyledTab label='Data Room' {...a11yProps(10)} />
      </StyledTabs>

      <TabPanel value={value} index={0}>
        <CreateEditOverview state={state} setState={dispatch} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <CreateEditBusiness state={state} setState={dispatch} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <CreateEditSolution state={state} setState={dispatch} />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <CreateEditMarket state={state} setState={dispatch} />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <CreateEditCompetition state={state} setState={dispatch} />
      </TabPanel>

      <TabPanel value={value} index={5}>
        <CreateEditOperations state={state} setState={dispatch} />
      </TabPanel>

      <TabPanel value={value} index={6}>
        <CreateEditMarketing state={state} setState={dispatch} />
      </TabPanel>

      <TabPanel value={value} index={7}>
        <CreateEditFinancials state={state} setState={dispatch} />
      </TabPanel>

      <TabPanel value={value} index={8}>
        <CreateEditRisk state={state} setState={dispatch} />
      </TabPanel>

      <TabPanel value={value} index={9}>
        {/* <CreateEditManagement /> */}
      </TabPanel>

      <TabPanel value={value} index={10}>
        <DataRoomComponent />
      </TabPanel>
    </>
  );
}