import React from "react";
import AccordianCard from "../../../../../../accordion-card/accordian-card";
import "../../../pitch-deck-analysis.css";
import PitchDeckDetails from "../../view-details/details-component";
import BulletPitchDeckDetail from "../../view-details/bullet-detail";
import PitchDeckContentLayout from "../../../../../../../layout/pitch-deck-analysis-page/pitch-deck-content-layout";

function ViewMarket({ data }) {
  return (
    <PitchDeckContentLayout data={data} type="market">
      <AccordianCard
        className="companyaccordion"
        title="Customers"
        activeKey="customers"
      >
        <BulletPitchDeckDetail
          data={data}
          displayName="Who are your customer segments whom you are serving and
                    providing value for?"
          fieldName="customerSegments"
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Market sizing"
        activeKey="marketsizing"
      >
        <BulletPitchDeckDetail
          data={data}
          displayName="How big are the market segments with the same problem worth
                    solving for and how large can the market become?"
          fieldName="marketSegments"
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Opportunity"
        activeKey="opportunity"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What is the specific customer problem that your
                    product-or-service solves?`,
              fieldName: "problemProductSolves",
              type: "bullets",
            },
            {
              displayName: `Does the product-or-service you're proposing make your
                    customers' lives better?`,
              fieldName: "doesProductMakeLivesBetter",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Market timing"
        activeKey="markettiming"
      >
        <BulletPitchDeckDetail
          data={data}
          displayName="Why now (market timing)?"
          fieldName="marketTiming"
        />
      </AccordianCard>
    </PitchDeckContentLayout>
  );
}

export default ViewMarket;
