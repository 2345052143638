import React, { useState, useEffect } from 'react';
import NavBar from '../../../../layout/navbar/navbar';
import SampleImage from '../../../../../assets/logo/roiquant - logo not found.png';
import ViewIdeaUniqueness from './view-idea-validation/idea-uniqueness';
import ViewBusinessViability from './view-idea-validation/business-viability';
import './idea-validation.css';
import { Accordion, Col, Card, ProgressBar, Container, Tabs, Tab, Alert, Spinner, Button } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH, FaEdit } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { S3Media } from '../../../../third-party-services';
import { useRealmApp } from '../../../../RealmApp';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

function ViewIdeaValidation() {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // get idea id
    const { ideaId } = useParams();

    // get current user
    const { currentUser } = useRealmApp();

    // check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // realm functions declaration
    const {
        // getMyIdea,
        getMyIdeaTest
    } = currentUser.functions;

    const [input, setInput] = useState(null);

    const [results, setResults] = useState(null);

    // const [state, setState] = useState(null);

    async function initState(ideaId) {

        try {

            // const response = await getMyIdea({ ideaId: ideaId });
            const response = await getMyIdeaTest({ ideaId: ideaId });

            // console.log("initState() response: ", response);

            setInput(response);
            setResults(response.result);
            setAlert(false);

        } catch (error) {
            // console.error("Failed to get data: ", error);
        }
    }

    useEffect(() => {
        initState(ideaId);
    }, []);

    return (
        <>
            <NavBar />

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="ideachecker">

                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="pitchdeckheaderdiv">
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdecktext">
                                {!input?.ideaValidationName
                                    ? ("Untitled idea")
                                    : input.ideaValidationName
                                }
                            </span>
                        </div>
                    </div>

                    <div>
                        <Tabs className="companytabs" defaultActiveKey="assumption">

                            <Tab eventKey="assumption" title="Assumption">
                                <div className="ideacheckertabbody">
                                    <Col className="ideacheckerquestions">

                                        <Accordion className="companyaccordion" defaultActiveKey="howUniqueIsYourIdea">
                                            <ViewIdeaUniqueness
                                                idea={input?.idea}
                                                industry={input?.industry}
                                                categories={input?.categories}
                                                location={input?.location}
                                                markets={input?.markets}
                                                entityType={input?.entityType}
                                                stageOfDevelopment={input?.stageOfDevelopment}
                                                businessModelType={input?.businessModelType}
                                                defensibilityMoat={input?.defensibilityMoat}
                                                reach={input?.reach}
                                                impact={input?.impact}
                                                confidence={input?.confidence}
                                                ease={input?.ease}
                                            />
                                        </Accordion>

                                        <Accordion className="companyaccordion" defaultActiveKey="howViableIsYourBusiness">
                                            <ViewBusinessViability
                                                customerSegment={input?.customerSegment}
                                                customerPain={input?.customerPain}
                                                marketingTiming={input?.marketingTiming}
                                                customerGain={input?.customerGain}
                                                competitors={input?.competitors}
                                                competitiveDifferentiation={input?.competitiveDifferentiation}
                                                monetizationModel={input?.monetizationModel}
                                                marketStrategy={input?.marketStrategy}
                                                marketSegments={input?.marketSegments}
                                                tamAmount={input?.tamAmount}
                                                tamUnit={input?.tamUnit}
                                                samAmount={input?.samAmount}
                                                samUnit={input?.samUnit}
                                                somAmount={input?.somAmount}
                                                somUnit={input?.somUnit}
                                                percentOfTam={input?.percentOfTam}
                                                percentOfSam={input?.percentOfSam}
                                                earlyRevenue={input?.earlyRevenue}
                                                earlyRevenueAmount={input?.earlyRevenueAmount}
                                                earlyRevenueUnit={input?.earlyRevenueUnit}
                                                kpiMetrics={input?.kpiMetrics}
                                                noOfUsers={input?.noOfUsers}
                                                noOfActiveUsers={input?.noOfActiveUsers}
                                                totalVisitorsPerMth={input?.totalVisitorsPerMth}
                                                noOfNewVisitorsPerMth={input?.noOfNewVisitorsPerMth}
                                                noOfReturningVisitorsPerMth={input?.noOfReturningVisitorsPerMth}
                                                noOfCustomersAtStartPeriod={input?.noOfCustomersAtStartPeriod}
                                                noOfLostCustomers={input?.noOfLostCustomers}
                                                totalCustomers={input?.totalCustomers}
                                                noOfExistingCustomersInNxtMth={input?.noOfExistingCustomersInNxtMth}
                                                financingStrategy={input?.financingStrategy}
                                                EBITDA={input?.EBITDA}
                                                businessValuation={input?.businessValuation}
                                                valuationAmount={input?.valuationAmount}
                                                valuationUnit={input?.valuationUnit}
                                                exitStrategy={input?.exitStrategy}
                                                solvencyDebtRatio={input?.solvencyDebtRatio}
                                                teamExperience={input?.teamExperience}
                                            />
                                        </Accordion>

                                        {!results?.similar_companies || results?.similar_companies.length === 0
                                            ? ""
                                            : (<Accordion defaultActiveKey="competitors" style={{ marginTop: "10px" }}>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="competitors">
                                                        <div className="companyarrowicon">
                                                            <FaChevronDown />
                                                        </div>

                                                        <div className="companytitlecard">
                                                            Direct competitors and similar companies with similar business offerings
                                                        </div>

                                                        <div className="companyellipsisicon">
                                                            <FaEllipsisH />
                                                        </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="competitors">
                                                        <Card.Body>
                                                            <div className="detaildiv">

                                                                <table className="detailtable">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No.</th>
                                                                            <th style={{ color: "transparent" }}>Logo/Image</th>
                                                                            <th>Name</th>
                                                                            <th>Entity type</th>
                                                                            <th>Description</th>
                                                                            <th>Reasons for shutdown</th>
                                                                            <th>Industry</th>
                                                                            <th>Categories</th>
                                                                            <th>Location</th>
                                                                            <th>Ownership status</th>
                                                                            <th>Stage of development</th>
                                                                            <th>Transactional model type</th>
                                                                            <th>Defensibility moats</th>
                                                                            <th>Last financing round</th>
                                                                            <th>Total raised</th>
                                                                            <th>Post-money valuation</th>
                                                                            <th>Last financing date</th>
                                                                            <th>No. of funding round(s)</th>
                                                                            <th>No. of investors</th>
                                                                            <th>Investors</th>
                                                                            <th>Total revenue</th>
                                                                            <th>Market capitalization</th>
                                                                            <th>No. of investments</th>
                                                                            <th>No. of acquisitions</th>
                                                                            <th>No. of board seats taken</th>
                                                                            <th>Competition</th>
                                                                            <th>Management</th>
                                                                            <th>No. of employees</th>
                                                                            <th>Company reputation</th>
                                                                            <th>Contact info</th>
                                                                            <th>Operational duration</th>
                                                                            <th>Shutdown date</th>
                                                                            <th>Founded date</th>
                                                                            <th>Legal name</th>
                                                                            <th>Former legal name(s)</th>
                                                                            <th>Ticker</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            results.similar_companies.slice(0, 50).map((company, index) =>
                                                                                <tr key={index}>
                                                                                    <td><p className="detailxsdata">{index + 1}.</p></td>

                                                                                    {!company.logo
                                                                                        ? (<td><img src={SampleImage} alt={company.name} className="idealogo" /></td>)
                                                                                        : (<td><img src={S3Media + 'assets/photos/logo/' + company.logo} alt={company.name} className="idealogo" /></td>)
                                                                                    }

                                                                                    {!company.name
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmediumdata">{company.name}</p></td>)
                                                                                    }

                                                                                    {!company.entitytype
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmediumdata">{company.entitytype}</p></td>)
                                                                                    }

                                                                                    {!company.description
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detaillongdata">{company.description}</p></td>)
                                                                                    }

                                                                                    {!company.reasonsforfailure
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmediumdata">{company.reasonsforfailure}</p></td>)
                                                                                    }

                                                                                    {!company.new_industry
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmldata">{company.new_industry}</p></td>)
                                                                                    }

                                                                                    {!company.new_categories
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmldata">{company.new_categories}</p></td>)
                                                                                    }

                                                                                    {!company.location
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmediumdata">{company.location}</p></td>)
                                                                                    }

                                                                                    {/* ownership status */}
                                                                                    <td className="detailemptydata"><p className="detailshortdata">-</p></td>

                                                                                    {!company.stageofdevelopment
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailshortdata">{company.stageofdevelopment}</p></td>)
                                                                                    }

                                                                                    {!company.businessmodeltype
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailshortdata">{company.businessmodeltype}</p></td>)
                                                                                    }

                                                                                    {!company.defensibilitymoat
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmldata">{company.defensibilitymoat}</p></td>)
                                                                                    }

                                                                                    {!company.lastfinancinground
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailshortdata">{company.lastfinancinground}</p></td>)
                                                                                    }

                                                                                    {!company.totalinvestment
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmediumdata">{company.totalinvestment}</p></td>)
                                                                                    }

                                                                                    {/* post-money valuation */}
                                                                                    <td className="detailemptydata"><p className="detailshortdata">-</p></td>

                                                                                    {!company.lastfinancingdate
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailshortdata">{company.lastfinancingdate}</p></td>)
                                                                                    }

                                                                                    {!company.numoffundingrounds
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailshortdata">{company.numoffundingrounds}</p></td>)
                                                                                    }

                                                                                    {!company.numofinvestors
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailshortdata">{company.numofinvestors}</p></td>)
                                                                                    }

                                                                                    {!company.investors
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detaillongdata">{company.investors}</p></td>)
                                                                                    }

                                                                                    {/* total revenue */}
                                                                                    <td className="detailemptydata"><p className="detailshortdata">-</p></td>

                                                                                    {/* market capitalization */}
                                                                                    <td className="detailemptydata"><p className="detailshortdata">-</p></td>

                                                                                    {/* no. of investments */}
                                                                                    <td className="detailemptydata"><p className="detailshortdata">-</p></td>

                                                                                    {!company.numofacquisitions
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailshortdata">{company.numofacquisitions}</p></td>)
                                                                                    }

                                                                                    {/* no. of board seats taken */}
                                                                                    <td className="detailemptydata"><p className="detailshortdata">-</p></td>

                                                                                    {!company.competitors && !company.similarentities
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detaillongdata">{!company.competitors ? "" : company.competitors}; {!company.similarentities ? "" : company.similarentities}</p></td>)
                                                                                    }

                                                                                    {!company.management
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detaillongdata">{company.management}</p></td>)
                                                                                    }

                                                                                    {!company.numofemployees
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailshortdata">{company.numofemployees}</p></td>)
                                                                                    }

                                                                                    {/* company reputation */}
                                                                                    <td className="detailemptydata"><p className="detailshortdata">-</p></td>

                                                                                    {!company.phonenumber
                                                                                        ? (<td className="detailemptydata"><p className="detailshortdata">-</p></td>)
                                                                                        : (<td><p className="detailshortdata">{company.phonenumber}</p></td>)
                                                                                    }


                                                                                    {!company.lifespan
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : company.lifespan !== 1
                                                                                            ? (<td><p className="detailshortdata">{company.lifespan} years</p></td>)
                                                                                            : (<td><p className="detailshortdata">{company.lifespan} year</p></td>)
                                                                                    }

                                                                                    {!company.endeddate
                                                                                        ? (<td className="detailemptydata"><p className="detailshortdata">-</p></td>)
                                                                                        : (<td><p className="detailshortdata">{company.endeddate}</p></td>)
                                                                                    }

                                                                                    {!company.starteddate
                                                                                        ? (<td className="detailemptydata"><p className="detailshortdata">-</p></td>)
                                                                                        : (<td><p className="detailshortdata">{company.starteddate}</p></td>)
                                                                                    }

                                                                                    {!company.legalname
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmediumdata">{company.legalname}</p></td>)
                                                                                    }

                                                                                    {!company.former_legalname
                                                                                        ? (<td className="detailemptydata">-</td>)
                                                                                        : (<td><p className="detailmediumdata">{company.former_legalname}</p></td>)
                                                                                    }

                                                                                    {/* ticker symbol */}
                                                                                    <td className="detailemptydata"><p className="detailshortdata">-</p></td>
                                                                                </tr>
                                                                            )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>)
                                        }
                                    </Col>

                                    <Col className="ideacheckerresults">
                                        <Accordion className="companyaccordion" defaultActiveKey="resultstats">
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="resultstats">
                                                    <div className="companytitlecard">
                                                        Results
                                                    </div>

                                                    <div className="companyarrowiconright">
                                                        <FaChevronDown />
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="resultstats">
                                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                                        {/* <div style={{ display: "flex" }}>
                                                                    <hr style={{ marginTop: 0 }} />

                                                                    <div style={{ textAlign: "left", flex: "10" }}>
                                                                        <FaCalendar />
                                                                    </div>

                                                                    <div style={{ textAlign: "center", flex: "75", fontSize: "14px", lineHeight: "30px" }}>
                                                                        1 January 2021 - 8 January 2021
                                                                    </div>

                                                                    <div style={{ textAlign: "right", flex: "15" }}>
                                                                        <FaChevronLeft />
                                                                        <FaChevronRight />
                                                                    </div>

                                                                    <hr style={{ marginBottom: 0 }} />
                                                                </div>

                                                                <br /> */}

                                                        <div style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
                                                            Risk of failure: {results?.failurerate ? results.failurerate : 0}%
                                                        </div>
                                                        <ProgressBar now={results?.failurerate ? results.failurerate : 0} variant="danger" style={{ height: "2rem" }} />

                                                        <br />

                                                        {
                                                            (results?.error)
                                                                ? (<Alert className="error-message" variant="danger" style={{ marginBottom: "0" }}>
                                                                    {results.error}
                                                                </Alert>)
                                                                : ""
                                                        }

                                                        <br />

                                                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>How we predict risk of failure?</span>

                                                        <br />

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Idea uniqueness</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.idea_sim
                                                                        ? results.idea_sim > -1
                                                                            ? results.idea_sim
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.idea_sim ? results.idea_sim : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.idea_sim_diff
                                                                    ? input.data.idea_sim_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.idea_sim_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.idea_sim_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Industry</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.res_industry?.percentage
                                                                        ? results.res_industry.percentage > -1
                                                                            ? results.res_industry.percentage
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.res_industry?.percentage ? results.res_industry.percentage : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.res_industry_diff
                                                                    ? input.data.res_industry_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.res_industry_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.res_industry_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Business categories (vertical markets)</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.res_categories?.percentage
                                                                        ? results.res_categories.percentage > -1
                                                                            ? results.res_categories.percentage
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.res_categories?.percentage ? results.res_categories.percentage : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.res_categories_diff
                                                                    ? input.data.res_categories_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.res_categories_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.res_categories_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Location</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.res_location?.percentage
                                                                        ? results.res_location.percentage > -1
                                                                            ? results.res_location.percentage
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.res_location?.percentage ? results.res_location.percentage : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.res_location_diff
                                                                    ? input.data.res_location_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.res_location_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.res_location_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Competitive landscape (similarity score)</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.compete_sim
                                                                        ? results.compete_sim > -1
                                                                            ? results.compete_sim
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.compete_sim ? results.compete_sim : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.compete_sim_diff
                                                                    ? input.data.compete_sim_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.compete_sim_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.compete_sim_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Reach, impact, confidence, ease (RICE score)</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.rice_score
                                                                        ? results.rice_score > -1
                                                                            ? results.rice_score
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.rice_score ? results.rice_score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.rice_score_diff
                                                                    ? input.data.rice_score_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.rice_score_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.rice_score_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Novelty score</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.dumb_sim
                                                                        ? results.dumb_sim > -1
                                                                            ? results.dumb_sim
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.dumb_sim ? results.dumb_sim : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.dumb_sim_diff
                                                                    ? input.data.dumb_sim_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.dumb_sim_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.dumb_sim_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <br />

                                                        <div style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
                                                            Average benchmarks score: {results?.avbenchmark
                                                                ? results.avbenchmark > -1
                                                                    ? results.avbenchmark
                                                                    : 0
                                                                : 0}%
                                                        </div>
                                                        <ProgressBar now={results?.avbenchmark ? results.avbenchmark : 0} variant="danger" style={{ height: "2rem" }} />

                                                        <br />

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>TAM</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.tamScore
                                                                        ? results.tamScore > -1
                                                                            ? results.tamScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.tamScore ? results.tamScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.tamScore_diff
                                                                    ? input.data.tamScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.tamScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.tamScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>SAM</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.samScore
                                                                        ? results.samScore > -1
                                                                            ? results.samScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.samScore ? results.samScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.samScore_diff
                                                                    ? input.data.samScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.samScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.samScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>SOM</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.somScore
                                                                        ? results.somScore > -1
                                                                            ? results.somScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.somScore ? results.somScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.somScore_diff
                                                                    ? input.data.somScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.somScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.somScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Revenue</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.revenueScore
                                                                        ? results.revenueScore > -1
                                                                            ? results.revenueScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.revenueScore ? results.revenueScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.revenueScore_diff
                                                                    ? input.data.revenueScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.revenueScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.revenueScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Active users</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.activeUserScore
                                                                        ? results.activeUserScore > -1
                                                                            ? results.activeUserScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.activeUserScore ? results.activeUserScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.activeUserScore_diff
                                                                    ? input.data.activeUserScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.activeUserScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.activeUserScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Returning visitors</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.returnVisitorScore
                                                                        ? results.returnVisitorScore > -1
                                                                            ? results.returnVisitorScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.returnVisitorScore ? results.returnVisitorScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.returnVisitorScore_diff
                                                                    ? input.data.returnVisitorScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.returnVisitorScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.returnVisitorScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>New visitors</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.newVisitorScore
                                                                        ? results.newVisitorScore > -1
                                                                            ? results.newVisitorScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.newVisitorScore ? results.newVisitorScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.newVisitorScore_diff
                                                                    ? input.data.newVisitorScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.newVisitorScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.newVisitorScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Churn rate</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.churnRateScore
                                                                        ? results.churnRateScore > -1
                                                                            ? results.churnRateScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.churnRateScore ? results.churnRateScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.churnRateScore_diff
                                                                    ? input.data.churnRateScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.churnRateScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.churnRateScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Customer retention</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.customerRetentionScore
                                                                        ? results.customerRetentionScore > -1
                                                                            ? results.customerRetentionScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.customerRetentionScore ? results.customerRetentionScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.customerRetentionScore_diff
                                                                    ? input.data.customerRetentionScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.customerRetentionScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.customerRetentionScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>EBITDA</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.ebitdaScore
                                                                        ? results.ebitdaScore > -1
                                                                            ? results.ebitdaScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.ebitdaScore ? results.ebitdaScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.ebitdaScore_diff
                                                                    ? input.data.ebitdaScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.ebitdaScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.ebitdaScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Valuation</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.valuationScore
                                                                        ? results.valuationScore > -1
                                                                            ? results.valuationScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.valuationScore ? results.valuationScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.valuationScore_diff
                                                                    ? input.data.valuationScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.valuationScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.valuationScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>

                                                        <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                                <p style={{ width: "80%", marginBottom: "0" }}>Debt-ratio</p>
                                                                <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                                                    {results?.debtRatioScore
                                                                        ? results.debtRatioScore > -1
                                                                            ? results.debtRatioScore
                                                                            : 0
                                                                        : 0}%
                                                                </p>
                                                            </div>
                                                            <ProgressBar now={results?.debtRatioScore ? results.debtRatioScore : 0} variant="danger" style={{ height: "0.2rem" }} />
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", fontSize: "10px" }}>
                                                                {input?.data?.debtRatioScore_diff
                                                                    ? input.data.debtRatioScore_diff.includes("+")
                                                                        ? <span style={{ color: "#0f9d58" }}>{input.data.debtRatioScore_diff}%</span>
                                                                        : <span style={{ color: "red" }}>{input.data.debtRatioScore_diff}%</span>
                                                                    : "0%"}
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>

                                        <Accordion defaultActiveKey="feedback" style={{ marginTop: "10px" }}>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="feedback" >
                                                    <div className="companytitlecard">
                                                        Feedback
                                                    </div>

                                                    <div className="companyarrowiconright">
                                                        <FaChevronDown />
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="feedback">
                                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                                        <div className="pitchdeckrecommended">
                                                            Recommended readings:

                                                            <Accordion className="ideacheckerrecommendedaccordion" defaultActiveKey="1">

                                                                {results?.general_read
                                                                    ? (<Card>
                                                                        <Accordion.Toggle as={Card.Header} eventKey="1" className="ideacheckerrecommendedheader">
                                                                            <div className="ideacheckerrecommendedtext">General</div>

                                                                            <div className="ideacheckerrecommendedarrow">
                                                                                <FaChevronDown />
                                                                            </div>
                                                                        </Accordion.Toggle>

                                                                        <Accordion.Collapse eventKey="1">
                                                                            <Card.Body>
                                                                                <div>
                                                                                    <ul style={{ paddingLeft: "17px" }}>
                                                                                        {results?.general_read.recommended_read.map((reading, index) =>
                                                                                            <li key={index}>
                                                                                                <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                                                            </li>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>)
                                                                    : ("")
                                                                }

                                                                {results?.unique_read
                                                                    ? (<Card>
                                                                        <Accordion.Toggle as={Card.Header} eventKey="2" className="ideacheckerrecommendedheader">
                                                                            <div className="ideacheckerrecommendedtext">Idea uniqueness</div>

                                                                            <div className="ideacheckerrecommendedarrow">
                                                                                <FaChevronDown />
                                                                            </div>
                                                                        </Accordion.Toggle>

                                                                        <Accordion.Collapse eventKey="2">
                                                                            <Card.Body>
                                                                                <div>
                                                                                    <ul style={{ paddingLeft: "17px" }}>
                                                                                        {results?.unique_read.recommended_read.map((reading, index) =>
                                                                                            <li key={index}>
                                                                                                <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                                                            </li>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>)
                                                                    : ("")
                                                                }

                                                                {results?.filter_read
                                                                    ? (<Card>
                                                                        <Accordion.Toggle as={Card.Header} eventKey="3" className="ideacheckerrecommendedheader">
                                                                            <div className="ideacheckerrecommendedtext">Industry, categories, location</div>

                                                                            <div className="ideacheckerrecommendedarrow">
                                                                                <FaChevronDown />
                                                                            </div>
                                                                        </Accordion.Toggle>

                                                                        <Accordion.Collapse eventKey="3">
                                                                            <Card.Body>
                                                                                <div>
                                                                                    <ul style={{ paddingLeft: "17px" }}>
                                                                                        {results?.filter_read.recommended_read.map((reading, index) =>
                                                                                            <li key={index}>
                                                                                                <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                                                            </li>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>)
                                                                    : ("")
                                                                }

                                                                {results?.competition_read
                                                                    ? (<Card>
                                                                        <Accordion.Toggle as={Card.Header} eventKey="4" className="ideacheckerrecommendedheader">
                                                                            <div className="ideacheckerrecommendedtext">Competitive landscape</div>

                                                                            <div className="ideacheckerrecommendedarrow">
                                                                                <FaChevronDown />
                                                                            </div>
                                                                        </Accordion.Toggle>

                                                                        <Accordion.Collapse eventKey="4">
                                                                            <Card.Body>
                                                                                <div>
                                                                                    <ul style={{ paddingLeft: "17px" }}>
                                                                                        {results?.competition_read.recommended_read.map((reading, index) =>
                                                                                            <li key={index}>
                                                                                                <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                                                            </li>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>)
                                                                    : ("")
                                                                }

                                                                {results?.novelty_read
                                                                    ? (<Card>
                                                                        <Accordion.Toggle as={Card.Header} eventKey="5" className="ideacheckerrecommendedheader">
                                                                            <div className="ideacheckerrecommendedtext">Novelty score</div>

                                                                            <div className="ideacheckerrecommendedarrow">
                                                                                <FaChevronDown />
                                                                            </div>
                                                                        </Accordion.Toggle>

                                                                        <Accordion.Collapse eventKey="5">
                                                                            <Card.Body>
                                                                                <div>
                                                                                    <ul style={{ paddingLeft: "17px" }}>
                                                                                        {results?.novelty_read.recommended_read.map((reading, index) =>
                                                                                            <li key={index}>
                                                                                                <a href={reading.LINK} target="_blank" rel="noopener noreferrer">{reading.TITLE}</a>
                                                                                            </li>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>)
                                                                    : ("")
                                                                }

                                                            </Accordion>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Col>
                                </div>
                            </Tab>

                            <Tab eventKey="modelling" title="Modelling" disabled>

                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default ViewIdeaValidation;