import React from "react";
import AccordianCard from "../../../../../../accordion-card/accordian-card";
import "../../../pitch-deck-analysis.css";
import PitchDeckDetails from "../../view-details/details-component";
import PitchDeckContentLayout from "../../../../../../../layout/pitch-deck-analysis-page/pitch-deck-content-layout";

function ViewCompetition({ data }) {
  return (
    <PitchDeckContentLayout data={data} type="competition">
      <AccordianCard
        className="companyaccordion"
        title="Competitors"
        activeKey="competitors"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `Who are your direct competitors in your competitive
                    landscape?`,
              fieldName: "directCompetitors",
              type: "bullets",
            },
            {
              displayName: `Who are your similar companies in your competitive
                    landscape?`,
              fieldName: "similarCompanies",
              type: "bullets",
            },
            {
              displayName: `What are their products and services?`,
              fieldName: "productsAndServices",
              type: "bullets",
            },
            {
              displayName: `How much market share does each competitor control?`,
              fieldName: "marketShare",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title={`Competitors' strength and weaknesses`}
        activeKey="competitorsstrengthsandweaknesses"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What are your competitors' strengths and weaknesses?`,
              fieldName: "strengthsAndWeaknesses",
              type: "bullets",
            },
            {
              displayName: `Do they enjoy strong brand recognition of their products or
                    services?`,
              fieldName: "doCompetitorsEnjoyRecognition",
              type: "bullets",
            },
            {
              displayName: `What are their marketing strategies?`,
              fieldName: "marketingStrategies",
              type: "bullets",
            },
            {
              displayName: `What are the key factors to their profitability?`,
              fieldName: "keyFactorsToProfitability",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title={`Competitive differentiation (unique value proposition)`}
        activeKey="competitiveDifferentiation"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What is your unique value proposition or unique selling
                    point differentiating you from your competition?`,
              fieldName: "competitiveDifferentiation",
              type: "bullets",
            },
            {
              displayName: `Why is your value proposition unique and compelling?`,
              fieldName: "whyValuePropositionIsUnique",
              type: "bullets",
            },
            {
              displayName: `How is your product ten times better or cheaper than
                    existing alternatives?`,
              fieldName: "howYourProductIsBetter",
              type: "bullets",
            },
            {
              displayName: `What differentiates your product-or-service from
                    competitors' offerings?`,
              fieldName: "differentiationsFromCompetitorsProduct",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title={`Industry competitive outlook`}
        activeKey="industryCompetitiveOutlook"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What is the competitive outlook for the industry?`,
              fieldName: "competitiveOutlookForIndustry",
              type: "bullets",
            },
            {
              displayName: `How much of a threat are your competitors to your venture?`,
              fieldName: "howCompetitorsThreaten",
              type: "bullets",
            },
            {
              displayName: `Will your competitors aggressively block the entrance of a
                    new rival?`,
              fieldName: "willCompetitorsBlock",
              type: "bullets",
            },
            {
              displayName: `Will your competitors poach your ideas, appropriating them
                    for their own business (killing your unique value
                    proposition)?`,
              fieldName: "willCompetitorsPoach",
              type: "bullets",
            },
          ]}
        />
      </AccordianCard>
    </PitchDeckContentLayout>
  );
}

export default ViewCompetition;
