import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewPlanBuilderPageLayout from "../../../../layout/pitch-deck-analysis-page/view";
import { useRealmApp } from "../../../../RealmApp";
import useBusinessPlanBuilder from "../../../hooks/useBusinessPlanBuilder";
import LoadingModal from "../../../modals/loading-modal";
import { closeAlert } from "./helpers";
import "./pitch-deck-analysis.css";

const ViewPitchDeckAnalysis = () => {
  // Hook for alert component
  const [alert, setAlert] = useState(true);

  // Hook for alert message
  const [message, setMessage] = useState(<LoadingModal />);

  // Hook to prevent unnecessary fetches
  const [hasFetched, setHasFetched] = useState(false);

  const { currentUser } = useRealmApp();

  const { getMyPitchDeck } = currentUser.functions;

  // Get pitchDeckId from URL params
  const { pitchDeckId } = useParams();

  // Use custom hook and rename `state` to `data`
  const { state: data, dispatch } = useBusinessPlanBuilder();

  const props = {
    data: data,
    setMessage,
    setAlert,
    message,
    alert,
  };

  const params = {
    ...props,
    closeAlert: () => closeAlert(props),
  };

  useEffect(() => {
    // Fetch pitch deck data
    async function getPitchDeck(id) {
      try {
        const response = await getMyPitchDeck({ pitchDeckId: id });
        const pitchDeck = JSON.parse(response);
        dispatch({ type: "initialize", value: pitchDeck });

        setAlert(false);
        setHasFetched(true);
      } catch (err) {
        console.error("Couldn't fetch data from Realm");
        setMessage("Failed to fetch data. Please try again later.");
        setAlert(true);
      }
    }

    if (pitchDeckId && !hasFetched) {
      getPitchDeck(pitchDeckId);
    }
  }, [pitchDeckId, getMyPitchDeck, hasFetched, dispatch]);

  // useEffect(() => {
  //   console.log("data:", data);
  // }, [data]);

  return <ViewPlanBuilderPageLayout props={params} />;
};

export default ViewPitchDeckAnalysis;
