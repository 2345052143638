import React, { useState, useEffect, useRef } from 'react'
import validator from 'validator'
import Check from '../../../assets/thank-you/green-check.png';
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import { Link } from 'react-router-dom';
import { Card, Button, Form, Alert, Spinner } from 'react-bootstrap'
import { useRealmApp } from '../../RealmApp'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FaHandPointRight } from 'react-icons/fa';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import TagManager from 'react-gtm-module';
import './thank-you.css'

/**
 * 
 * This is the page customers are brought to after their Stripe Checkout Sessions end.
 * 
 * MongoDB Realm does not dynamically update a user's custom data if the underlying document changes.
 * Instead, MongoDB Realm fetches a new copy of the data whenever a user refreshes their access token,
 * such as when they log in.
 * 
 * Customers have to log in again to fetch updates to their tiers
 */

function ThankYouPage() {

    const { isLoggedIn, currentUser, logIn, resendConfirmation } = useRealmApp()



    useEffect(() => {
        if (currentUser) {
            async function generateId() {
                try {
                    let response = await currentUser.functions.createId();

                    let name1 = "referralLink";
                    let name2 = "customReferralLink";
                    let name3 = "text";
                    let value = "https://app.roiquant.com/create-account/" + response;

                    // create a copy of filters with desired changes
                    let logChange = { ...log };

                    logChange[name1] = value;
                    logChange[name2] = value;
                    setLog({ ...logChange });

                    // create a copy of filters with desired changes
                    let copyChanged = { ...copy };

                    copyChanged[name3] = value;
                    setCopy({ ...copyChanged });
                } catch (err) {
                    // console.error("Unable to generate ID");
                }
            }
            generateId();
        }
    }, [currentUser]);





    const referralCopyButton = useRef(null);

    // hook for log (to be saved in referrals collection)
    const [log, setLog] = useState({
        emailTo: "",
        emailFrom: "",
        emailContent: "",
        referralLink: "",
        customReferralLink: "",
        referralType: "Anonymous invitation",
        method: ""
    });



    // hook for copy
    const [copy, setCopy] = useState({
        text: "",
        copied: false
    });

    // handle copy function
    async function copyText() {
        let name1 = "copied";
        let value1 = true;

        // create a copy of filters with desired changes
        let copyChanged = { ...copy };

        copyChanged[name1] = value1;
        setCopy({ ...copyChanged });
    }

    // alert hook
    const [alert, setAlert] = useState(false);

    // message hook
    const [message, setMessage] = useState("");

    // function to close alert
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    useEffect(() => {
        // console.log(log);

        const handleClick = async (event) => {
            // console.log("button clicked");
            if (currentUser) {
                try {
                    await currentUser.functions.saveReferralInvitation(log);

                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Copied</p>
                        <p style={{ marginLeft: "28px" }}>Your link has been copied.</p>
                    </div>);
                } catch (err) {
                    // console.error("Could not save the log:", err);
                }
            }
        };

        const element = referralCopyButton.current;

        if (element !== null) {
            element.addEventListener("click", handleClick);

            return () => {
                element.removeEventListener("click", handleClick);
            };
        }

    }, [copy, log, currentUser]);

    const [credentials, setCredentials] = useState({
        email: '',
        pwd: ''
    });





    const [billingOverview, setBillingOverview] = useState('#');

    const [showForm, setShowForm] = useState(true);

    useEffect(() => {

        if (currentUser) {

            async function getUrl() {


                if (currentUser.customData.stripeCustomerId) {

                    let url;

                    try {

                        url = await currentUser.functions.getMyStripeCustomerPortalLink();

                    } catch (error) {
                        // error is an Error object

                        // TODO: Handle error
                        if (error.message) {
                            const errorMssg = error.message;

                            if (errorMssg.includes("mode not specified")) {

                                // console.log("ERROR:" + "CONFIGURATION ERROR");
                            }
                            else if (errorMssg.includes("failed to create session")) {

                                // console.log("ERROR: " + "FAILED TO CREATE SESSION");
                            } else {

                                // console.log("ERROR: ", "UNHANDLED ERROR");
                            }
                        }
                    }

                    if (url) {
                        setBillingOverview(url);
                    }
                    else {
                        setBillingOverview("#");
                    }
                }
            }
            getUrl();
        }
    }, [currentUser]);

    function loadBillingOverview() {
        // show loading message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're connecting to your billing customer portal.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        if (billingOverview !== "#") {
            // show loading message
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Ready</p>
                <p style={{ marginLeft: "28px" }}>Your billing customer portal is ready. <Link to={billingOverview} target="_blank" rel="noopener noreferrer" className="alert-cta">View billing overview</Link></p>
            </div>);
        }
    }


    async function handleResendConfirmation(email) {
        const isValidEmailAddress = validator.isEmail(email);

        if (isValidEmailAddress) {
            try {

                await resendConfirmation(email);

            } catch (error) {

                // console.error('Coudn\'t log in:', error.error)
                setAlert(true);

                // To-do: Write an error message for this situation.
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>Unable to resend confirmation: {error.error}</p>
                </div>);
            }

        } else {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Invalid email address.</p>
            </div>);
        }
    }

    async function handleLogin(credentials) {
        const isValidEmailAddress = validator.isEmail(credentials.email)

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Attempting to upgrade plan</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        // Log in the user
        if (isValidEmailAddress) {
            try {

                await logIn(credentials.email, credentials.pwd)
                // console.log('User is logged in!')
                // if (currentUser) {
                //     if (currentUser.customData.recentStripeEvent === "invoice.paid") setShowForm(false);
                //     else if (currentUser.customData.recentStripeEvent === "checkout.session.completed") {
                //         // TODO: Write code here
                //     }
                // }

                setAlert(false);
                setShowForm(false);


            } catch (error) {


                // console.error("You Cannot log in:", error.error);


                switch (error.error) {
                    case "confirmation required":
                        setAlert(true);
                        setMessage(<div style={{ paddingTop: "10px" }}>
                            <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Account verification</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Your account is not yet verified. For security reasons, you need to verify your account.</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>We've sent you an email, kindly follow the instruction for verification and then log into your roiquant account.</p>
                            <div style={{ textAlign: "right" }}>
                                <Button className="thankyouloginbtn glintanimation" onClick={() => handleResendConfirmation(credentials.email)}>
                                    Re-send verification email
                                </Button>
                            </div>
                        </div>);

                        break;

                    case "invalid username/password":
                        setAlert(true);
                        setMessage(<div style={{ paddingTop: "10px" }}>
                            <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>The sign-in information is not correct. Please enter a correct email or password, and try again.</p>
                        </div>);

                        break;

                    default:
                        setAlert(true);
                        setMessage(<div style={{ paddingTop: "10px" }}>
                            <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>An unknown error occurred. Please try again.</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If error persists, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                        </div>);
                }
            }
        } else {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Invalid email address.</p>
            </div>);
        }
    }



    return (
        <div id="thankyoucontainer">
            {
                showForm
                    ? <ThankYouForm
                        handleLogin={handleLogin}
                        credentials={credentials}
                        setCredentials={setCredentials}
                        handleResendConfirmation={handleResendConfirmation}

                        alert={alert}
                        closeAlert={closeAlert}
                        message={message}
                    />

                    : (<>

                        {alert
                            ? (<Alert className="error-message floating-alert" variant="light">
                                <div>
                                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                                    {message}
                                </div>
                            </Alert>)
                            : ""
                        }

                        <div id="thankyouloginform" style={{ padding: "80px 0" }}>
                            <Card className="thankyoucard">
                                <div style={{ textAlign: "center" }}>
                                    <div style={{ paddingBottom: "40px" }}>
                                        <img src={Check} alt="" style={{ width: "120px", height: "120px" }} />
                                    </div>

                                    <p className="thankyouheader">Thank you for your payment and support!</p>

                                    <div className="thankyoutext">
                                        <p>We've securely completed your payment and you'll receive a confirmation email shortly.</p>
                                        <p>
                                            For additional information about your billing, service charge details, billing history, or to print
                                            your invoice/receipt, please check {billingOverview == "#"
                                                ? <a onClick={loadBillingOverview}>Billing Overview</a>
                                                : <a href={billingOverview} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline" }}>Billing Overview</a>}.
                                        </p>
                                    </div>


                                    <div className="thankyoutext">
                                        <p className="thankyouheader2"><FaHandPointRight style={{ color: "gold" }} /> <a href="/referral-program">Join our referral program</a> to invite your friends and get rewarded!</p>

                                        <p>Copy your referral link</p>

                                        <div className="referralinviteemaildiv">
                                            <Form.Control className="inputbox" type="text" name="referralLink" value={log.referralLink} disabled />

                                            <CopyToClipboard text={copy.text} onCopy={copyText}>
                                                <Button
                                                    ref={referralCopyButton}
                                                    className="referralinvitesendbutton glintanimation">
                                                    <span>Copy</span>
                                                </Button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>

                                    <div className="thankyoubutton">
                                        <a href="/dashboard">
                                            <Button className="thankyoudashboardbutton glintanimation">Go back to dashboard</Button>
                                        </a>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </>)
            }
        </div>
    )
}

function ThankYouForm(props) {


    /*** To-do: Ask what the following is for.

    <div className="createaccalerttop">
        {responseMssg}
        <br></br>
        {responseMssg2}
    </div>

    ***/

    const [passwordShown, setPasswordShown] = useState(false);

    //Password toggle handler (New password)
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    function measurePwdLength() {
        //Measure the password length
        return (props.credentials.pwd)
        // return pwd
    }

    function checkPwdLength(pwd) {
        //Check if password is atleast 8 characters
        return isLength(pwd, { min: 8 })
    }

    function checkPwdSymbol(pwd) {
        //Check if password contains at least 1 symbol
        return isStrongPassword(pwd, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 1, minLowercase: 0 })
    }

    function checkPwdUppercase(pwd) {
        //Check if password contains at least 1 uppercase
        return isStrongPassword(pwd, { minUppercase: 1, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 0 })
    }

    function checkPwdLowercase(pwd) {
        //Check if password contains at least 1 lowercase
        return isStrongPassword(pwd, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 1 })
    }

    function checkPwdNum(pwd) {
        //Check if password contains at least 1 number
        return isStrongPassword(pwd, { minUppercase: 0, minLength: 0, minNumbers: 1, minSymbols: 0, minLowercase: 0 })
    }

    return (
        <>

            {props.alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={props.closeAlert}><IoMdClose /></Button></div>
                        {props.message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="thankyouloginform">
                <Card className="thankyoulogincard">
                    <div className="thankyouloginheader">
                        <h3>Confirm your new plan</h3>
                    </div>

                    <div className="thankyoucreateaccnote">
                        <p>
                            Additional authentication required
                            <br />
                            Please enter email and password to continue.
                        </p>
                    </div>

                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control className="thankyouinputbox" type="email" name="email" placeholder="Email"
                                onChange={(e) => props.setCredentials(Object.assign({}, props.credentials, { email: e.target.value }))}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Control className="thankyouinputbox" type={passwordShown ? "text" : "password"} name="password" placeholder="Password"
                                onChange={(e) => props.setCredentials(Object.assign({}, props.credentials, { pwd: e.target.value }))}
                            />
                            <a href="#" className='showpassword' onClick={togglePassword}>{passwordShown ? "Hide" : "Show"} password</a>

                            <div style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "15px", marginBottom: "15px" }}>
                                <div id="checksField" style={{ width: "50%", paddingLeft: "15%" }}>
                                    <p id="char" style={{ color: measurePwdLength(props.credentials.pwd) == 0 ? "grey" : checkPwdLength(props.credentials.pwd) ? "green" : "red" }}>At least 8 characters</p>

                                    <p id="char" style={{ color: measurePwdLength(props.credentials.pwd) == 0 ? "grey" : checkPwdSymbol(props.credentials.pwd) ? "green" : "red" }}>At least 1 symbol character</p>

                                    <p id="char" style={{ color: measurePwdLength(props.credentials.pwd) == 0 ? "grey" : checkPwdNum(props.credentials.pwd) ? "green" : "red" }}>At least 1 number</p>
                                </div>

                                <div id="checksField" style={{ width: "50%" }}>
                                    <p id="char" style={{ color: measurePwdLength(props.credentials.pwd) == 0 ? "grey" : checkPwdLowercase(props.credentials.pwd) ? "green" : "red" }}>At least 1 lowercase letter</p>

                                    <p id="char" style={{ color: measurePwdLength(props.credentials.pwd) == 0 ? "grey" : checkPwdUppercase(props.credentials.pwd) ? "green" : "red" }}>At least 1 uppercase letter</p>
                                </div>
                            </div>
                        </Form.Group>

                        <div style={{ textAlign: "center" }}>
                            <Button className="thankyouloginbtn glintanimation" onClick={(event) => { props.handleLogin(props.credentials); event.preventDefault(); }} type="submit">
                                <span>Upgrade plan</span>
                            </Button>
                        </div>

                    </Form>

                </Card>
            </div >


        </>

    )
}

export default ThankYouPage