import React from "react";
import PitchDeckContentLayout from "../../../../../../../layout/pitch-deck-analysis-page/pitch-deck-content-layout";
import AccordianCard from "../../../../../../accordion-card/accordian-card";
import "../../../pitch-deck-analysis.css";
import PitchDeckDetail from "../../view-details/detail";
import PitchDeckDetails from "../../view-details/details-component";

function ViewOverview({ data }) {
  return (
    <PitchDeckContentLayout data={data} type="overall">
      <AccordianCard
        className="companyaccordion"
        title="General information"
        activeKey="generalinformation"
        id="companyinformation"
      >
        <div className="companydetailrows">
          <PitchDeckDetails
            className="pitchdeckdetaildiv"
            data={data}
            detailsArray={[
              { displayName: "Entity name", fieldName: "entityName" },
              { displayName: "Entity type", fieldName: "entityType" },
              {
                displayName: "Ownership status",
                fieldName: "ownershipStatus",
              },
              { displayName: "Market(s)", fieldName: "markets" },
            ]}
          />
        </div>

        <div className="companydetailrows">
          <PitchDeckDetails
            className="pitchdeckdetaildiv"
            data={data}
            detailsArray={[
              {
                displayName: "Headquarter location - city",
                fieldName: "locationCity",
              },
              {
                displayName: "Headquarter location - state",
                fieldName: "locationState",
              },
              {
                displayName: "Headquarter location - country",
                fieldName: "locationCountry",
              },
            ]}
          />
        </div>

        <PitchDeckDetail
          data={data}
          displayName="One-sentence pitch"
          fieldName="onesentencePitch"
        />

        <div className="companydetailrows">
          <PitchDeckDetails
            className="pitchdeckdetaildiv"
            data={data}
            detailsArray={[
              {
                displayName: "Stage of development",
                fieldName: "stageOfDevelopment",
              },
              {
                displayName: "Transactional model type",
                fieldName: "transactionalModelType",
              },
              {
                displayName: "Categories",
                fieldName: "categories",
              },
              {
                displayName: "Competitive moat(s)",
                fieldName: "defensibilityMoats",
              },
            ]}
          />
        </div>
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Goals"
        activeKey="goals"
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: "Vision",
              fieldName: "vision",
            },
            {
              displayName: "Mission",
              fieldName: "mission",
            },
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: "10px" }}
        title="Funding"
        activeKey="funding"
      >
        <div className="companydetailrows">
          <PitchDeckDetails
            data={data}
            className="pitchdeckdetaildiv"
            detailsArray={[
              {
                displayName: "Last financing date",
                fieldName: "lastFinancingDate",
              },
              {
                displayName: "Last financing round",
                fieldName: "lastFinancingRound",
              },
              {
                displayName: "No. of funding rounds",
                fieldName: "numOfFundingRounds",
              },
            ]}
          />
        </div>
      </AccordianCard>
    </PitchDeckContentLayout>
  );
}

export default ViewOverview;
