import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import './login-form.css'
import { Card, Button, Form, Modal } from 'react-bootstrap'
import { BsDot } from "react-icons/bs";
import HeaderLogo from '../../../assets/navbar/roiquant-600x200px-white-01.png';

function LoginForm(props) {

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [openModal, setOpenModal] = useState(false);

  function openForm() {
    setOpenModal(true);
  }

  function hideForm() {
    setOpenModal(false);
  }

  const [option, setOption] = useState("I'd like to request a verification email to activate my new account");

  function handleChange(e) {
    e.preventDefault();

    let value = e.target.value;

    setOption(value);
  }

  const history = useHistory();

  function directToResetPasswordPage() {
    history.push("/reset-password/?email=" + encodeURIComponent(props.credentials.email));
  }

  return (
    <>
      <div id="logincontent">

        <div className="logintop">
          <a href="https://www.roiquant.com/" target="_blank" rel="noopener noreferrer">
            <img src={HeaderLogo} alt="roiquant" width="180px" height="60px" className="roiquantlogo" />
          </a>
        </div>

        <div className="createaccalerttop">
          {props.response}
        </div>

        <div id="loginform">
          <Card className="logincard">
            <div className="loginheader">
              <h3>Log into your roiquant account</h3>
            </div>

            <div className="createaccnote">
              <p>Don't have an account? <a href="/create-account">Create free account</a></p>
            </div>

            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Control className="inputbox" type="email" name="email" placeholder="Email" value={props.credentials.email}
                  onChange={(e) => props.setCredentials(Object.assign({}, props.credentials, { email: e.target.value }))}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control className="inputbox" type={passwordShown ? "text" : "password"} name="password" placeholder="Password"
                  onChange={(e) => props.setCredentials(Object.assign({}, props.credentials, { password: e.target.value }))}
                />

                <a href="#" className='showpassword' onClick={togglePassword}>{passwordShown ? "Hide" : "Show"} password</a>

                <div style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: "15px", marginBottom: "-15px" }}>
                  <div id="checksField" style={{ width: "50%", paddingLeft: "15%" }}>

                    <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdLength(props.credentials.password) ? "green" : "red" }}>At least 8 characters or as long as possible</p>

                    <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdSymbol(props.credentials.password) ? "green" : "red" }}>Include at least 1 symbol character</p>

                    <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdNum(props.credentials.password) ? "green" : "red" }}>Include at least 1 number</p>
                  </div>

                  <div id="checksField" style={{ width: "50%" }}>
                    <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdLowercase(props.credentials.password) ? "green" : "red" }}>At least 1 lowercase letter</p>

                    <p id="char" style={{ color: props.measurePwdLength(props.credentials.password) == 0 ? "grey" : props.checkPwdUppercase(props.credentials.password) ? "green" : "red" }}>At least 1 uppercase letter</p>
                  </div>
                </div>
              </Form.Group>

              <div style={{ textAlign: "center", marginBottom: "-15px" }}>
                <Button className="loginbtn glintanimation" type="submit"
                  onClick={(event) => {
                    event.preventDefault()
                    props.handleLogin(props.credentials)
                  }}>
                  <span>Log in</span>
                </Button>
              </div>

              <div className="forgotpw">
                <a href="/reset-password">Forgot password?</a><BsDot /><a onClick={openForm}>Still can't log in?</a>
              </div>

            </Form>

          </Card>
        </div>

      </div>

      <Modal className="getintouchformdiv" show={openModal} onHide={hideForm} backdrop="static" fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className="getintouchformheader" style={{ borderBottom: "none" }} />
        <Modal.Body className="getintouchformbody">

          <div className="loginheader">
            <h3>Still can't log in?</h3>
          </div>

          <div>
            <Form>
              Email<sup>*</sup>
              <Form.Group controlId="formBasicEmail">
                <Form.Control className="inputbox" type="email" name="email" placeholder="Enter your email address" value={props.credentials.email}
                  onChange={(e) => props.setCredentials(Object.assign({}, props.credentials, { email: e.target.value }))}
                />
              </Form.Group>

              Request<sup>*</sup>
              <Form.Group controlId="formBasicAbout">
                <Form.Control required className="formbox" as="select" name="request" value={option} placeholder="" onChange={handleChange}>
                  <option>I'd like to request a verification email to activate my new account</option>
                  <option>I'd like to set a new password to log into my account</option>
                </Form.Control>
              </Form.Group>

              <div style={{ textAlign: "center" }}>
                {option === "I'd like to request a verification email to activate my new account"
                  ? <Button className="loginbtn glintanimation" type="submit"
                    onClick={(event) => {
                      event.preventDefault()
                      props.handleResendConfirmation(props.credentials.email)
                    }}
                  >
                    <span>Re-send verification email</span>
                  </Button>
                  : option === "I'd like to set a new password to log into my account"
                    ? <Button className="loginbtn glintanimation"
                      onClick={directToResetPasswordPage}
                    >
                      <span>Submit</span>
                    </Button>
                    : ""
                }
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}

export default LoginForm